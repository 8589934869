import React, { useCallback } from 'react';
import { TableCell } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import styled from '@emotion/styled';
import omit from 'lodash/omit';
import { Sandboxing } from 'models/Sandboxing';
import { TablePagination, EPageDirection } from 'views/components/table/TablePagination';
import { Row } from 'views/components/table/CustomTable';
import CustomTable from 'views/components/table/CustomTable';
import CustomTableHead from 'views/components/table/CustomTableHead';
import EmptySandboxing, { ForbiddenOptions, emptyRowData } from '../../EmptySandboxing';
import MySandboxingTableRow from './MySandboxingTableRow';

interface IMySandboxingTableProps {
  results: Sandboxing[];
  more: boolean;
  refetchItem: (sha256: string, id: string) => void;
  refetch: (filters?: { key: string; value: string }[], fetchOffset?: boolean) => void;
  accessForbidden?: ForbiddenOptions;
  filters: { key: string; value: string }[];
  paginationProps: { [key: string]: any };
}

const messages = {
  sandboxTarget: 'Target',
  sandboxOn: 'Sandboxed On',
  sandboxType: 'Type',
  sha256: 'SHA-256',
  sandboxProvider: 'Sandbox Provider',
  score: 'Mal Score',
  status: 'Status',
  triggeredBy: 'Triggered By',
};

const Toolbar = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const MySandboxingTable = ({
  filters,
  results,
  more,
  refetch,
  refetchItem,
  accessForbidden,
  paginationProps,
}: IMySandboxingTableProps) => {
  const { isFirst, isLast, offset, limit, onChangePage, onChangeRowsPerPage } = paginationProps;
  const paginatedData = results.slice(offset, offset + limit);

  const PaginatedComponent = useCallback(
    () =>
      paginatedData.length ? (
        <TablePagination
          rowsPerPage={limit}
          offset={offset}
          total={results.length}
          rowsPerPageOptions={[5, 25, 50, 100, 200]}
          onChangePage={(direction) => {
            if (direction === EPageDirection.NEXT && isLast && more) {
              refetch(filters.map((item) => omit(item, 'isDefault')));
            }
            onChangePage(direction);
          }}
          onChangeRowsPerPage={onChangeRowsPerPage}
          isFirst={isFirst}
          hasMore={more}
          isLast={isLast && !more}
        />
      ) : (
        <></>
      ),
    [
      filters,
      isFirst,
      isLast,
      limit,
      more,
      offset,
      onChangePage,
      onChangeRowsPerPage,
      paginatedData.length,
      refetch,
      results.length,
    ]
  );

  const rows: Row<Sandboxing>[] = [
    {
      id: 'sandboxOn',
      numeric: false,
      label: messages.sandboxOn,
      sortable: false,
    },
    {
      id: 'target',
      numeric: false,
      label: messages.sandboxTarget,
      sortable: false,
    },
    {
      id: 'type',
      numeric: false,
      label: messages.sandboxType,
      sortable: false,
    },
    {
      id: 'sha256',
      numeric: false,
      label: messages.sha256,
      sortable: false,
    },
    {
      id: 'sandboxProvider',
      numeric: false,
      label: messages.sandboxProvider,
      sortable: false,
    },
    {
      id: 'score',
      numeric: false,
      label: messages.score,
      sortable: false,
    },
    {
      id: 'status',
      numeric: false,
      label: messages.status,
      sortable: false,
    },
    {
      id: 'triggeredBy',
      numeric: false,
      label: messages.triggeredBy,
      sortable: false,
    },
  ];

  return (
    <div>
      {!accessForbidden ? (
        <Toolbar>
          <Button
            component='span'
            color='primary'
            size='small'
            variant='text'
            onClick={() => refetch(filters, false)}
          >
            Reload
          </Button>
        </Toolbar>
      ) : null}
      <CustomTable<Sandboxing>
        rows={accessForbidden ? emptyRowData : paginatedData}
        renderTableHead={() => (
          <CustomTableHead
            rows={rows}
            showActions={true}
            renderHeaderCell={(row) => (
              <TableCell key={row.id} align={row.numeric ? 'right' : 'left'}>
                {row.label}
              </TableCell>
            )}
          />
        )}
        renderTableRow={(row: Sandboxing) => (
          <MySandboxingTableRow
            blur={!!accessForbidden}
            refetch={refetch}
            refetchItem={refetchItem}
            key={`${row.sha256}-${row.id}`}
            rowData={row}
          />
        )}
        renderPagination={<PaginatedComponent />}
      />
      {accessForbidden && <EmptySandboxing type='mySandboxing' forbidden={accessForbidden} />}
    </div>
  );
};
export default MySandboxingTable;
