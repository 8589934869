import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import styles from 'views/styles';
import { UserProps } from 'views/components/providers/UserProvider';
import Card from 'views/components/layout/Card';
import { Tooltip } from '@material-ui/core';
import { UserAvatar } from 'views/components/Avatar';
import { makeStyles } from 'views/components/providers/ThemeProvider';

interface Props extends UserProps {
  className?: string;
  shrink?: boolean;
}

const AccountSidebar = ({ shrink = false, className, user }: Props) => {
  const { classes } = useStyles();
  const { username, name, email, firstName, lastName, accountNumber } = user;

  return (
    <Card className={!shrink ? className : ''} css={[!shrink ? classes.root : classes.shrinkRoot]}>
      <Tooltip
        interactive
        title={
          shrink ? (
            <div style={{ padding: '1em', textAlign: 'center' }}>
              <div css={classes.tooltipInfo}>
                <p style={{ color: 'unset' }} className='h4 h-mb-tiny' data-cy='profileUsername'>
                  {username ?? name}
                </p>
                <p data-cy='profileName'>{`${firstName} ${lastName}`}</p>
                <p>{email}</p>
                <p>#{accountNumber}</p>
              </div>
            </div>
          ) : (
            ''
          )
        }
        placement='right'
      >
        <div css={[!shrink ? classes.avatarWrap : classes.shrinkAvatarWrap]}>
          {name && (
            <UserAvatar
              css={[classes.avatar, shrink && classes.shrinkAvatar]}
              name={name}
              accountNumber={`${accountNumber}`}
            />
          )}
        </div>
      </Tooltip>
      {!shrink && (
        <div css={classes.infoWrap}>
          <p className='h4 h-mb-tiny' data-cy='profileUsername'>
            {username ?? name}
          </p>
          <p data-cy='profileName'>{`${firstName} ${lastName}`}</p>
          <p>{email}</p>
          <p>#{accountNumber}</p>
        </div>
      )}
    </Card>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      padding: `0 ${styles.spacing.sm}`,
      textAlign: 'center',
      wordBreak: 'break-all',
    },
    shrinkRoot: {
      boxShadow: '0 8px 24px rgba(0, 0, 0, 0.12)',
      borderRadius: '6px 6px 0 0',
      background: '#353154',
      padding: '8px',
      textAlign: 'center',
      wordBreak: 'break-all',
      width: '80px',
      marginBottom: '1.1px',
    },
    shrinkAvatarWrap: {
      padding: '24px 0',
    },
    avatarWrap: {
      padding: `${styles.spacing.sm} 0`,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      fontSize: '3.5rem',
    },
    avatar: {
      margin: '0 auto',
    },
    shrinkAvatar: {
      width: '4em !important',
    },
    infoWrap: {
      padding: `${styles.spacing.sm} 0`,
    },
    tooltipInfo: {
      gap: '1em',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  light: {
    avatarWrap: {
      borderBottomColor: styles.border.color.grey,
    },
  },
  dark: {
    avatarWrap: {
      borderBottomColor: styles.border.color.medDarkPurple,
    },
  },
});

export default AccountSidebar;
