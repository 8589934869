import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ScanResultsPage from './ScanResultsPage';
import ScanPage from './ScanPage';

const ScanRoutes = ({
  scanEnabled,
  scanResultsEnabled,
}: {
  scanEnabled: boolean;
  scanResultsEnabled: boolean;
}) => (
  <Switch>
    <Route
      path={[
        '/scan/results/:artifactType(file|url)/:uuid/:submissionId(\\d+)?/:action?',
        '/scan/results/:uuid/:submissionId(\\d+)?/:action?',
      ]}
      component={ScanResultsPage}
      show={scanResultsEnabled}
    />
    <Route exact path='/scan' component={ScanPage} show={scanEnabled} />
    <Redirect to='/404' />
  </Switch>
);

export default ScanRoutes;
