import React, { useCallback } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import styled from '@emotion/styled';
import isEmpty from 'lodash/isEmpty';
import capitalize from 'lodash/capitalize';
import { useDispatch } from 'react-redux';
import { ILimitedAccessMember } from 'models/Team';
import Icon from 'views/components/Icon';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import { Page } from 'models/Page';
import { Button, Tooltip, Chip, TableCell, TableRow } from '@material-ui/core';
import HoverableTableRow from 'views/components/table/HoverableTableRow';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { FULL_DATE_FORMAT } from 'utils/constants';
import { openModal } from 'state/modal/actions';
import { patchApiKeyTags } from 'state/apiKeys/actions';
import { DeleteApiKeyDialog } from 'views/components/table/ApiKeysTable/DeleteApiKeyDialog';

interface ITeamMembersRow {
  teamMembers: Page<ILimitedAccessMember>;
  rowData: ILimitedAccessMember;
  archiveApiKey: (id: string, key: string) => void;
}

const Actions = styled.div`
  white-space: nowrap;
  display: inline-flex;
`;

const formatDate = (date: string) => {
  const zonedDate = utcToZonedTime(date, 'UTC');

  return format(zonedDate, FULL_DATE_FORMAT);
};

const TeamMembersRow = ({ rowData, teamMembers, archiveApiKey }: ITeamMembersRow) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { classes } = useStyles(isOpen);

  const _toggleOpen = React.useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  const access = rowData.type === 'portal-external' ? 'UI' : 'API';

  const HeaderComponent = useCallback(
    () => {
      const team = rowData.team_external
        ? `${rowData.team_external.name} - #${rowData.team_external.account_number}`
        : '-';
      return (
        <>
          <TableCell>{rowData.user.email}</TableCell>
          <TableCell>{team}</TableCell>
          <TableCell>{capitalize(rowData.community)}</TableCell>
          <TableCell>{access.toUpperCase()}</TableCell>
          <TableCell>
            <div style={{ display: 'flex', gap: 8 }}>
              {`${rowData.expiration ? formatDate(rowData.expiration) : 'Never expires'}`}{' '}
              {rowData.expiration && new Date(rowData.expiration) <= new Date() ? (
                <Tooltip
                  title='Limited Access Key has expired and no longer active.'
                  placement='top'
                >
                  <div>
                    <Icon css={classes.iconWarning} name='warning' />
                  </div>
                </Tooltip>
              ) : null}
            </div>
          </TableCell>
          <TableCell align='left'>
            <Actions>
              <Tooltip title='Delete API Key' placement='top'>
                <div>
                  <DeleteApiKeyDialog
                    title='Delete Limited Access team'
                    text='Are you sure you want to delete the Limited Access permission?'
                    onDeleteApiKey={() => archiveApiKey(rowData.id, rowData.user.email)}
                  />
                </div>
              </Tooltip>
              <button
                style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0)' }}
                css={classes.btnToggle}
                onClick={_toggleOpen}
              >
                <Icon name='arrow' title='toggle' />
              </button>
            </Actions>
          </TableCell>
        </>
      );
    },
    [rowData] // eslint-disable-line
  );

  const scopes = rowData?.scope?.feature_tags ?? [];
  const tags = rowData?.tags?.filter((tag) => !tag.includes('team:')) || [];

  const openEditApiKeyTagsModal = () => {
    dispatch(
      openModal('EDIT_API_KEY_TAGS', {
        onSubmit: async (values: { tags: string[] }) => {
          return dispatch(patchApiKeyTags(rowData.id, values.tags));
        },
        fields: { tags },
        email: rowData?.user.email,
      })
    );
  };

  return (
    <>
      <HoverableTableRow renderCells={() => <HeaderComponent />} />{' '}
      {isOpen && (
        <>
          <TableRow>
            <TableCell colSpan={11}>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '24px',
                  marginLeft: 16,
                  maxWidth: '100%',
                  overflow: 'auto',
                }}
              >
                <label style={{ lineHeight: '1.5', verticalAlign: 'middle' }}>Scopes:</label>
                {!isEmpty(scopes)
                  ? scopes.map((scope) => <Chip label={scope} />)
                  : 'No scopes available'}
              </div>
            </TableCell>
          </TableRow>{' '}
          <TableRow>
            <TableCell colSpan={11}>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '24px',
                  marginLeft: 16,
                  maxWidth: '100%',
                  overflow: 'auto',
                }}
              >
                <label style={{ lineHeight: '1.5', verticalAlign: 'middle' }}>Tags:</label>
                {!isEmpty(tags) ? tags.map((tag) => <Chip label={tag} />) : 'No tags available'}
                <Button onClick={openEditApiKeyTagsModal} size='small' color='primary'>
                  edit
                </Button>
              </div>
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  );
};

const useStyles = makeStyles((isOpen: boolean) => ({
  base: {
    cellContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
    },
    iconContainer: {
      height: '2.5rem',
      width: '2.5rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '100%',
      padding: '0.6rem',
    },
    icon: {
      fontSize: '0.4rem',
    },
    btnToggle: {
      cursor: 'pointer',
      fontSize: '0.32rem',
      padding: '0.5rem',
      transform: isOpen ? 'rotate(180deg)' : 'rotate(0)',
      transition: `color ${styles.easing.time} ${styles.easing.main}`,
    },
    iconWarning: {
      color: styles.color.lightRed,
      width: '1.8rem !important',
      height: '1.8rem !important',
    },
  },
  light: {
    iconContainer: {
      backgroundColor: styles.color.green,
      border: `2px solid ${styles.color.purple}`,
    },
    icon: {
      color: styles.color.purple,
    },
    btnToggle: {
      color: styles.color.xLightGrey,
      '&:hover': {
        color: styles.color.purple,
      },
    },
  },
  dark: {
    iconContainer: {
      backgroundColor: styles.color.green,
      border: `2px solid ${styles.color.lightBlue}`,
    },
    icon: {
      color: styles.color.lightBlue,
    },
    btnToggle: {
      color: styles.color.xLightGrey,
      '&:hover': {
        color: styles.color.lightBlue,
      },
    },
  },
}));
export default TeamMembersRow;
