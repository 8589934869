/**
 * Get all the keys that are of type string from an enum.
 */
export const getEnumKeys = <E extends object>(e: E) =>
  Object.keys(e).filter((k: any) => isNaN(k)) as (keyof E)[];


/**
 * Return true only if the element is not null or undefined,
 * use it with `Array.filter` to not only filter nullable values
 * but also to guard the type.
 */
export const isDefined = <T>(argument: T | undefined | null): argument is T => {
  return argument !== undefined && argument !== null;
};
