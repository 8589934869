import React, { useContext } from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsxRuntime classic */ /** @jsx jsx */
import { useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Tooltip } from '@material-ui/core';
import { CONSUMER_DOCS_URL } from 'utils/constants';
import { UserProps } from 'views/components/providers/UserProvider';
import { messages } from './messages';
import { useTheme } from 'views/components/providers/ThemeProvider';
import Button from '@material-ui/core/Button';
import { btnMessages } from 'views/components/Button';
import HeaderNav from './HeaderNav';
import UserMenu from 'views/components/menu/UserMenu';
import ConfigMenu from 'views/components/menu/ConfigMenu';
import GlobalDownloadProgress from 'views/components/GlobalDownload';
import { GlobalDownloadProgressContext } from 'views/components/providers/GlobalDownloadProvider';
import { MenuOption } from 'views/components/menu/UserMenu/UserMenu';
import Icon from 'views/components/Icon';
import Logo from './Logo';
import { AuthProps } from 'views/components/providers/AuthProvider';
import { ThemeState } from 'models/Theme';
import { ContextAccountPros, parseContextAccount } from 'state/auth/selectors';
import IconContainer from 'views/components/Icon/IconContainer';
import { parseSwitchURL } from 'views/url';
import { useIsPageEnabled } from 'hooks/useIsPageEnabled';
import { removeAccountKey } from 'utils/storage/storage';

export interface IHeader {
  className?: string;
  isMaintenanceMode: boolean;
  onSwitchTheme: (theme: ThemeState) => void;
  onCreateTeam: () => void;
}

type Props = IHeader & UserProps & AuthProps & ContextAccountPros;

const Header = ({
  className,
  onSwitchTheme,
  onCreateTeam,
  user,
  ctx,
  isMaintenanceMode,
  isAuthenticated,
  login,
  logout,
}: Props) => {
  const { error, inProgressItems, completedItems, pendingItems } = useContext(
    GlobalDownloadProgressContext
  );

  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const page = useIsPageEnabled();
  const showManualAnalysis = !!user.manualAnalysisContextId;

  const teamName = user.context && user.context.team && user.context.team.name;
  const team = user.teams.find((t) => `${t.accountNumber}` === `${ctx?.accountNumber}`);

  const accountNumber = team ? team.accountNumber : user.accountNumber;

  const getAccountMenuItems = () => {
    const items: any[] = [
      {
        id: user.username,
        username: user.username,
        accountNumber: `${user.accountNumber}`,
        isActive: String(user.accountNumber) === String(ctx?.accountNumber),
        onClick: () => {
          ctx?.accountNumber && removeAccountKey(ctx?.accountNumber, 'lastContextAccount');
          window.open(
            parseSwitchURL({
              context: 'user',
              accountNumber: user.accountNumber,
              isPrivate: false,
              isLimitedAccess: false,
            }),
            '_blank'
          );
        },
        dataCy: 'userAccountBtn',
        spacer: user.teams.length > 1 || isAuthenticated,
      },
    ];

    const externalItems: any[] = [];

    user.teams
      .sort((a, z) => a.name.localeCompare(z.name))
      .forEach((team) => {
        const accountNumber = `${team.accountNumber}`;

        items.push({
          id: team.name + '-public',
          accountNumber: accountNumber,
          teamName:
            team.hasPrivateCommunity && isAuthenticated ? team.name + ' - Public' : team.name,
          dataCy: 'teamAccountBtn',
          isActive:
            !ctx || (String(team.accountNumber) === String(ctx.accountNumber) && !ctx?.isPrivate),
          onClick: () => {
            ctx?.accountNumber && removeAccountKey(ctx?.accountNumber, 'lastContextAccount');
            window.open(parseSwitchURL(team.accountNumber), '_blank');
          },
        });

        if (team.hasPrivateCommunity && isAuthenticated) {
          items.push({
            isPrivate: true,
            id: team.name + '-private',
            teamName: team.name + ' - Private',
            accountNumber: accountNumber,
            dataCy: 'teamAccountBtn',
            isActive:
              ctx && String(team.accountNumber) === String(ctx.accountNumber) && ctx.isPrivate,
            onClick: () => {
              ctx?.accountNumber && removeAccountKey(ctx?.accountNumber, 'lastContextAccount');
              window.open(
                parseSwitchURL({
                  context: 'team',
                  accountNumber: team.accountNumber,
                  isPrivate: true,
                  isLimitedAccess: false,
                }),
                '_blank'
              );
            },
          });
        }
      });

    user.teamsExternal
      ?.filter(
        (team) => team.featureTags?.length !== 1 || team.featureTags[0] !== 'manual_analysis'
      )
      .forEach((team) => {
        const accountNumber = `${team.accountNumber}`;
        const hasPrivateCommunity =
          'hasPrivateCommunity' in team ? team.hasPrivateCommunity : false;

        externalItems.push({
          id: team.contextId,
          accountNumber: accountNumber,
          teamName:
            team.name +
            (hasPrivateCommunity
              ? team.community === 'private'
                ? ' - Private'
                : ' - Public'
              : ''),
          dataCy: 'teamAccountBtn',
          isActive: !ctx || String(team.contextId) === String(ctx.id),
          onClick: () => {
            ctx?.accountNumber && removeAccountKey(ctx?.accountNumber, 'lastContextAccount');
            window.open(
              parseSwitchURL({
                context: 'limited',
                id: team.contextId,
                accountNumber: team.accountNumber,
                isPrivate: team.community === 'private',
                isLimitedAccess: true,
              }),
              '_blank'
            );
          },
        });
      });

    if (items.length && isAuthenticated && !externalItems.length) {
      items[items.length - 1].spacer = true;
    } else if (externalItems.length && isAuthenticated) {
      externalItems[externalItems.length - 1].spacer = true;
    }

    return { items, externalItems };
  };

  const handleGoToSettings = () => {
    if (user.context?.team) {
      history.push(`/u/${parseContextAccount(ctx, true)}/settings/team`);
    } else {
      history.push('/account/profile');
    }
  };

  const displayGlobalDownload =
    !error && !!(pendingItems.length || inProgressItems.length || completedItems.length);

  const { items: userMenuItems, externalItems: externalMenuItems } = getAccountMenuItems();
  const actionsMenuItems = [
    {
      id: 'add-team',
      iconName: 'plus-alt',
      label: intl.formatMessage(messages.createTeam),
      onClick: onCreateTeam,
      spacer: true,
      dataCy: 'addTeamBtn',
    },
    {
      id: 'logout',
      iconName: 'power',
      label: intl.formatMessage(messages.logout),
      onClick: () => logout('/'),
      dataCy: 'logoutBtn',
    },
  ];

  const headerOptions = [
    {
      id: 1,
      to: '/scan',
      label: messages.scan,
      dataCy: 'scanNavBtn',
      hide: !page.isEnabled('scan') || !page.shouldBeOnNavbar('scan'),
    },
    {
      id: 2,
      to: '/search',
      label: messages.search,
      dataCy: 'searchNavBtn',
      hide: !page.isEnabled('search') || !page.shouldBeOnNavbar('search'),
    },
    {
      id: 3,
      to: '/hunt',
      label: messages.hunt,
      dataCy: 'huntNavBtn',
      hide: !page.isEnabled('hunt') || !page.shouldBeOnNavbar('hunt'),
    },
    {
      id: 4,
      to: '/sandbox',
      label: messages.sandbox,
      dataCy: 'sandboxNavBtn',
      hide: !page.isEnabled('sandbox') || !page.shouldBeOnNavbar('sandbox'),
    },
    {
      id: 5,
      to: '/manual-analysis',
      label: messages.manualAnalysis,
      dataCy: 'manualAnalysisNavBtn',
      hide:
        showManualAnalysis && isAuthenticated
          ? false
          : !page.isEnabled('manual-analysis') ||
            !page.shouldBeOnNavbar('manual-analysis') ||
            !showManualAnalysis ||
            !isAuthenticated,
    },
  ];

  return (
    <header className={className} css={style.root}>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div css={style.content}>
              <Logo />
              <HeaderNav options={headerOptions} />
              <div style={{ display: 'flex', alignItems: 'center', gap: '1.5rem' }}>
                {displayGlobalDownload && <GlobalDownloadProgress />}
                {isAuthenticated && ctx?.isPrivate && (
                  <Tooltip title='You are currently in Private mode' placement='bottom'>
                    <div>
                      <IconContainer>
                        <Icon css={style.icon} name='community_mode' />
                      </IconContainer>
                    </div>
                  </Tooltip>
                )}
                {isAuthenticated && (
                  <ConfigMenu
                    items={[
                      {
                        id: 'settings',
                        iconName: 'gear',
                        label: intl.formatMessage(messages.settings),
                        onClick: () => handleGoToSettings(),
                        dataCy: 'settingsBtn',
                        spacer: true,
                        isShown: !user.context?.isLimitedAccess,
                      },
                      {
                        id: 'theme',
                        iconName: theme === 'light' ? 'sun' : 'moon',
                        label: intl.formatMessage(
                          messages[theme === 'light' ? 'darkThemeOff' : 'darkThemeOn']
                        ),
                        onClick: () => onSwitchTheme(theme === 'light' ? 'dark' : 'light'),
                        dataCy: `${theme}ThemeBtn`,
                      },
                      {
                        id: 'help',
                        iconName: 'help',
                        label: intl.formatMessage(messages.help),
                        href: CONSUMER_DOCS_URL,
                        isShown: page.isEnabled('docs'),
                      },
                    ]}
                  />
                )}
                {isAuthenticated ? (
                  <UserMenu
                    username={user.username}
                    name={user.name}
                    teamName={teamName}
                    accountNumber={`${accountNumber}`}
                    showCommunityMode={team?.hasPrivateCommunity}
                    communityMode={ctx?.isPrivate ? 'Private' : 'Public'}
                    items={userMenuItems as MenuOption[]}
                    externalItems={externalMenuItems as MenuOption[]}
                    actionsMenuItems={actionsMenuItems as MenuOption[]}
                  />
                ) : (
                  <Button
                    style={{ fontSize: '1.6rem' }}
                    color='primary'
                    variant='outlined'
                    onClick={() => login(location.pathname)}
                    disabled={isMaintenanceMode}
                    data-cy='loginBtn'
                  >
                    <FormattedMessage {...btnMessages.login} />
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

const style = {
  root: css`
    padding: 1.8rem 0;
  `,
  content: css`
    display: flex;
    align-items: center;
    max-width: 90vw;
  `,
  icon: css`
    font-size: 1.4rem !important;
  `,
};
export default Header;
