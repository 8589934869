import React, { Fragment, useState, useEffect, useCallback, useRef } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import { isEmpty, get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Dropzone from 'react-dropzone';
import { RootState } from 'state/root';
import shortenFilename from 'utils/shortenFilename';
import formatBytes from 'utils/formatBytes';
import getUrlType, { UrlScanType } from 'utils/isUrl';
import { parse as parseTld } from 'tldts';
import { RequestError, ErrorKeys, errorMessages, translateError } from 'utils/error';
import { useSystem } from 'views/components/providers/SystemProvider';
import { Dispatch } from 'state/types/thunk';
import {
  submitV2File as submitFile,
  cancelFileUpload,
  submitUrl,
  getFileSizeLimit,
  getSubmission,
} from 'state/submission/actions';
import { errorSelector, loadingSelector } from 'state/requests/selectors';
import { SubmissionActionName } from 'state/submission/types';
import { Artifact } from 'models/Submission';
import { Filter } from 'views/components/utils';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import styles from 'views/styles';
import ScanFileState from './ScanFileState';
import UploadUnavailableState from 'views/components/FileUpload/UploadUnavailableState';
import FileUploadingState from 'views/components/FileUpload/FileUploadingState';
import PrivacyTerms from 'views/components/FileUpload/PrivacyTerms';
import UrlSearchingState from 'views/components/FileUpload/UrlSearchingState';
import useOnSelection from './useOnSelection';
import useIsPrivateContext from 'hooks/useIsPrivateContext';
import FileShareDataModal from 'components/FileUpload/FileShareDataModal';
import { checkIsZipFile } from 'utils/files';

interface IFileUpload {
  className?: string;
  showTerms?: boolean;
}

interface StateProps {
  isLoading: boolean;
  reqErr?: RequestError;
  uuid: string | null;
  scanResults: Artifact | null;
  fileSizeLimit: number | null;
}

type ViewState = 'default' | 'uploading' | 'searching-url';
type ZipConfig = {
  isZip: boolean;
  zipPassword?: string;
};

interface FileState {
  file: File | null;
  filename: string;
  filesize: string;
  zipConfig?: ZipConfig;
}

const initialFileState: FileState = {
  file: null,
  filename: '',
  filesize: '',
};

const errorKeys: ErrorKeys = {
  empty_file: errorMessages.emptyFile.id,
  file_size_too_large: errorMessages.fileTooLarge.id,
  rate_limit_exceeded: errorMessages.rateLimit.id,
};
const QRCodeAllowedExtentions = ['image/jpeg', 'image/png', 'image/gif'];

export const FileUpload = ({ className, showTerms }: IFileUpload) => {
  const [viewState, setViewState] = useState<ViewState>('default');
  const [urlScanType, setUrlScanType] = useState<UrlScanType>('url');
  const [error, setError] = useState<Error>();
  const [fileState, setFileState] = useState<FileState>(initialFileState);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [isSelectingFile, setIsSelectingFile] = useState(false);
  const [query, setQuery] = useState('');
  const [isEnteringQuery, setIsEnteringQuery] = useState(false);
  const [scanSearching, setScanSearching] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const { isMaintenanceMode } = useSystem();
  const { hasShareData, file: pendingSelectedFile, onSelection } = useOnSelection({});
  const [scanResults, setScanResults] = useState<Artifact | null>(null);
  const [isCancel, setCancel] = useState(false);
  const isPrivateContext = useIsPrivateContext();

  const intl = useIntl();
  const dispatch = useDispatch<Dispatch>();
  const { classes } = useStyles();
  const [isZipFile, setIsZipFile] = useState(false);
  const [couldBeQRCode, setCouldBeQRCode] = useState(false);
  const [loadedFile, setLoadedFile] = useState<File>();

  const { file, filename, filesize } = fileState;

  const {
    isLoading,
    reqErr,
    uuid,
    scanResults: scanResultsData,
    fileSizeLimit,
  } = useSelector<RootState, StateProps>(({ requests, submission }) => ({
    isLoading: loadingSelector(requests, [
      SubmissionActionName.SUBMIT_FILE,
      SubmissionActionName.SUBMIT_URL,
      SubmissionActionName.GET_SUBMISSION_UUID,
    ]),
    reqErr: errorSelector(requests, [
      SubmissionActionName.SUBMIT_FILE,
      SubmissionActionName.SUBMIT_URL,
      SubmissionActionName.GET_SUBMISSION,
      SubmissionActionName.GET_SUBMISSION_UUID,
    ]),
    uuid: submission.uuid,
    scanResults: submission.scanResults && submission.scanResults.result,
    fileSizeLimit: submission.fileSizeLimit,
  }));

  const isFailure = !!scanResults && scanResults.bounty_state === 5;
  const scanBounty = !!scanResults ? scanResults.bounty_state : 0;
  const failureReason = isFailure && !!scanResults ? scanResults.failed_reason : null;

  const _resetState = () => {
    setViewState('default');
    setError(undefined);
    setFileState(initialFileState);
    setFileUploaded(false);
    setIsSelectingFile(false);
    setQuery('');
    setIsEnteringQuery(false);
    setScanSearching(false);
    setPercentage(0);
  };

  const _validateAndUploadFile = async (file: File, zipConfig?: ZipConfig, isQRCode?: boolean) => {
    const filename = shortenFilename(file.name);
    const filesize = formatBytes(file.size);
    _resetState();
    setFileState({
      file,
      filename,
      filesize,
    });

    if (file.size <= 0) {
      setError(new Error('empty_file'));
    } else if (fileSizeLimit && file.size > fileSizeLimit) {
      setError(
        new Error(
          `File size exceeds upload limit on your plan (${formatBytes(
            fileSizeLimit
          )}). Upgrade your plan to upload larger files`
        )
      );
    } else {
      setViewState('uploading');
      await _handleFileUpload(file, zipConfig, isQRCode);
      setCancel(false);
    }
  };

  const _handleFileSelection = async (files: File[], zipConfig?: ZipConfig, isQRCode?: boolean) => {
    if (!files.length) return;

    const file = files[0];
    await _validateAndUploadFile(file, zipConfig, isQRCode);
  };

  const _handleFileUpload = async (file: File, zipConfig?: ZipConfig, isQRCode?: boolean) => {
    const submitRes = await dispatch(submitFile(file, zipConfig, isQRCode, setPercentage));
    await dispatch(getSubmission(submitRes.result.id));
    setFileUploaded(true);
  };

  const _submissionPollingTimeout = useRef<number>();

  /**
   * Stop polling submission by id
   */
  const _stopPollingSubmission = useCallback((isCancel?: boolean, isFail?: boolean) => {
    window.clearInterval(_submissionPollingTimeout.current);
    setFileUploaded(!isFail);

    if (isCancel) {
      _submissionPollingTimeout.current = undefined;
    }
  }, []);

  /**
   * Start polling submission by id until window will be closed
   */
  const _startPollingSubmission = useCallback(() => {
    if (!!scanResults && viewState !== 'default') {
      _submissionPollingTimeout.current = window.setInterval(() => {
        dispatch(getSubmission(scanResults.id));
      }, 3000);
    }
  }, [viewState, scanResults, dispatch]);

  useEffect(() => {
    if (isFailure) {
      _stopPollingSubmission(false, true);
    }

    if (!_submissionPollingTimeout.current && !isCancel && fileUploaded) {
      _startPollingSubmission();
    }

    if (!!_submissionPollingTimeout.current && scanBounty === 2) {
      _stopPollingSubmission();
    }
  }, [
    isCancel,
    scanBounty,
    isFailure,
    fileUploaded,
    _stopPollingSubmission,
    _startPollingSubmission,
  ]);

  useEffect(() => {
    if (
      fileUploaded &&
      viewState !== 'default' &&
      !_submissionPollingTimeout.current &&
      !isEmpty(scanResults) &&
      !!scanResults?.id
    ) {
      _startPollingSubmission();
    }
  }, [fileUploaded, viewState, _startPollingSubmission, scanResults, scanResults?.id]);

  useEffect(() => {
    return () => {
      // stop intervals when transitions to other pages
      _stopPollingSubmission();
    };
  }, []); // eslint-disable-line

  const _handleCancel = () => {
    _resetState();
    dispatch(cancelFileUpload());
    _stopPollingSubmission(true);
    setCancel(true);
  };

  const _handleRetry = async () => {
    if (file) {
      await _validateAndUploadFile(file);
    } else {
      _resetState();
      setIsSelectingFile(true);
    }
    _submissionPollingTimeout.current = undefined;
  };

  const _handleScanSearch = (query: string) => {
    const trimmedQuery = query.trim();
    const urlType = getUrlType(trimmedQuery);
    const urlData = parseTld(trimmedQuery);
    if (urlType && (urlData.isIcann || urlData.isIp)) {
      _handleSearchUrl(trimmedQuery, urlType);
    } else {
      setError(new Error('invalid_url'));
    }
  };

  const _handleSearchUrl = (url: string, urlScanType: UrlScanType) => {
    setViewState('searching-url');
    setUrlScanType(urlScanType);
    setFileUploaded(false);
    setScanSearching(true);
    setQuery(url);

    dispatch(submitUrl(url))
      .then(() => {
        setFileUploaded(true);
        setScanSearching(false);
      })
      .catch(() => setScanSearching(false));
  };

  const _handleRetrySearch = () => {
    _resetState();
    setIsEnteringQuery(true);
  };

  const _cleanError = () => {
    setError(undefined);
  };

  useEffect(() => {
    if (!fileSizeLimit) {
      dispatch(getFileSizeLimit());
    }
  }, [dispatch, fileSizeLimit]);

  useEffect(() => {
    if (scanResultsData && JSON.stringify(scanResultsData) !== JSON.stringify(scanResults)) {
      setScanResults(scanResultsData);
    }
  }, [scanResults, scanResultsData]);

  return (
    <>
      <Dropzone
        onDrop={async (files) => {
          const isZip = await checkIsZipFile(files[0]);

          if (isZip) {
            setIsZipFile(true);
            setLoadedFile(files[0]);
          } else if (QRCodeAllowedExtentions.includes(files[0].type)) {
            setCouldBeQRCode(true);
            setLoadedFile(files[0]);
          } else {
            onSelection(_handleFileSelection)(files);
          }
        }}
      >
        {({ getRootProps, isDragActive }) => (
          <Container>
            <div
              data-cy='fileSelect'
              className={className}
              css={[classes.root, isPrivateContext && classes.background]}
              {...getRootProps({
                onClick: (e) => e.stopPropagation(),
                tabIndex: -1,
              })}
            >
              {isMaintenanceMode ? (
                <UploadUnavailableState />
              ) : (
                <Filter
                  currentState={viewState}
                  states={{
                    default: (
                      <ScanFileState
                        isDragActive={isDragActive}
                        isInputInitiallyVisible={isEnteringQuery}
                        selectFileWhenMounted={isSelectingFile}
                        onFileSelect={async (files, checkZip = true, zipConfig, isQRCode) => {
                          if (QRCodeAllowedExtentions.includes(files[0].type) && !loadedFile) {
                            setCouldBeQRCode(true);
                            setLoadedFile(files[0]);
                          } else if (checkZip) {
                            const isZip = await checkIsZipFile(files[0]);
                            if (isZip) {
                              setIsZipFile(true);
                              setLoadedFile(files[0]);
                            } else {
                              onSelection(_handleFileSelection)(files);
                            }
                          } else {
                            if (couldBeQRCode || isZipFile) {
                              onSelection(() => {
                                _handleFileSelection(files, zipConfig, isQRCode);
                              })(files);
                            } else {
                              _handleFileSelection(files, zipConfig, isQRCode);
                            }
                          }
                        }}
                        onScanSearch={_handleScanSearch}
                        error={error}
                        cleanError={_cleanError}
                        file={loadedFile}
                        isZipFile={isZipFile}
                        couldBeQRCode={couldBeQRCode}
                      />
                    ),
                    uploading: (
                      <FileUploadingState
                        isLoading={isLoading}
                        fileUploaded={fileUploaded}
                        errorMessage={
                          error || reqErr
                            ? translateError(intl, errorKeys, error || reqErr)
                            : isFailure
                            ? failureReason || 'Fail Uploading'
                            : ''
                        }
                        percentage={percentage}
                        filename={filename}
                        filesize={filesize}
                        uuid={uuid || scanResults?.sha256}
                        submissionId={get(scanResults, 'id')}
                        renderFailureBtn={() => (
                          <Button
                            style={{ fontSize: '2.4rem' }}
                            className='h-mt-sm'
                            color='primary'
                            variant='contained'
                            onClick={_handleRetry}
                          >
                            {intl.formatMessage({ id: 'button.retry' })}
                          </Button>
                        )}
                        onCancel={_handleCancel}
                      />
                    ),
                    'searching-url': (
                      <UrlSearchingState
                        type={urlScanType}
                        isLoading={isLoading || scanSearching}
                        fileUploaded={fileUploaded}
                        error={error || reqErr}
                        uuid={uuid}
                        submissionId={get(scanResults, 'id')}
                        url={query}
                        renderFailureBtn={() => (
                          <SearchingBtnGroup
                            onRetryUpload={_handleRetry}
                            onRetrySearch={_handleRetrySearch}
                          />
                        )}
                        onCancel={_handleCancel}
                      />
                    ),
                  }}
                />
              )}
              {showTerms && <PrivacyTerms css={classes.terms} />}
            </div>
          </Container>
        )}
      </Dropzone>
      <FileShareDataModal
        onCancel={() => {
          const input = document.getElementById('scan-file-state-select') as HTMLInputElement;
          if (input) {
            input.value = '';
          }
        }}
        onSubmit={() => {
          if (pendingSelectedFile) {
            _handleFileSelection(pendingSelectedFile);
          }
        }}
        open={!!pendingSelectedFile && hasShareData === null && !hasShareData}
      />
    </>
  );
};

const SearchingBtnGroup = ({
  onRetryUpload,
  onRetrySearch,
}: {
  onRetryUpload: () => void;
  onRetrySearch: () => void;
}) => {
  const intl = useIntl();
  return (
    <Fragment>
      <Button
        style={{ fontSize: '2.4rem' }}
        className='h-mt-md h-mb-xxs'
        color='primary'
        variant='contained'
        onClick={onRetrySearch}
        data-cy='scanSearchRetry'
      >
        {intl.formatMessage({ id: 'button.retry' })}
      </Button>
      <Button style={{ fontSize: '2.2rem' }} color='primary' variant='text' onClick={onRetryUpload}>
        {intl.formatMessage({ id: 'fileupload.uploaded.button' })}
      </Button>
    </Fragment>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      borderRadius: styles.spacing.tiny,
      padding: styles.spacing.lg,
      position: 'relative',
      outline: 'none',
    },
    terms: {
      position: 'absolute',
      bottom: '2rem',
      left: 0,
      width: '100%',
      textAlign: 'center',
    },
  },
  light: {
    background: {
      backgroundColor: styles.color.white,
    },
  },
  dark: {
    background: {
      backgroundColor: styles.color.xxDarkPurple,
    },
  },
});
