import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { Dispatch } from 'state/types/thunk';
import { RootState } from 'state/root';
import { TeamActionName } from 'state/team/types';
import { getTeamByAccount, getTeamAccount } from 'state/team/actions';
import styled from '@emotion/styled';
import styles from 'views/styles';
import ability from 'config/ability';
import SEO from 'views/components/layout/SEO';
import BaseLayout from 'views/components/layout/BaseLayout';
import PageSidebarLayout from 'views/components/layout/PageSidebarLayout';
import { combinedUserActions } from 'views/components/providers/UserProvider';
import { useUser } from 'views/components/providers/UserProvider';
import AppLoader from 'views/components/request/AppLoader';
import HuntSidebar from './HuntSidebar';
import HuntPanel from './HuntPanel';
import { contextAccount } from 'state/auth/selectors';
import { store } from 'state/store';

const Wrapper = styled.div`
  margin: ${styles.spacing.sm};
  position: relative;
`;

const messages = defineMessages({
  title: {
    id: 'huntPage.title',
    defaultMessage: 'Hunt',
  },
});

const HuntPage = () => {
  const intl = useIntl();
  const user = useUser();
  const ctx = contextAccount(store);
  const dispatch = useDispatch<Dispatch>();
  const { single: team } = useSelector(({ team }: RootState) => team);
  const { refreshUser } = useUser();
  const abilities =
    ability.can('live_hunt', 'Artifact') || ability.can('historical_hunt', 'Artifact');

  const _getTeam = async () => {
    if (ctx?.accountNumber && team?.accountNumber) {
      if (ctx.context === 'team' && String(ctx.accountNumber) !== String(team.accountNumber)) {
        await dispatch(getTeamByAccount(ctx.accountNumber));
        dispatch(getTeamAccount(ctx.accountNumber));
      }
    }
  };

  useEffect(() => {
    _getTeam();
  }, [user.context]); // eslint-disable-line

  return (
    <Fragment>
      <SEO title={intl.formatMessage(messages.title)} />
      <BaseLayout>
        <AppLoader
          loadingActions={[
            ...combinedUserActions,
            TeamActionName.GET_TEAM,
            TeamActionName.GET_TEAM_ACCOUNT,
          ]}
          onReload={refreshUser}
        >
          {() =>
            abilities ? (
              <Wrapper>
                <HuntPanel />
              </Wrapper>
            ) : (
              <PageSidebarLayout
                renderSidebar={() => <HuntSidebar />}
                renderPanel={() => <HuntPanel />}
              />
            )
          }
        </AppLoader>
      </BaseLayout>
    </Fragment>
  );
};

export default HuntPage;
