import { AuthState } from '.';
import { RootState } from '../root';

import { EmptyObject, Store } from 'redux';
import { matchPath } from 'react-router';
import { removeAccountKey, storeAccountKey, getAccountStoredKey } from 'utils/storage/storage';

export const isAuthenticated = (state: AuthState) => {
  const expiresAt = state.tokens && state.tokens.expiresAt;
  return !!expiresAt && new Date().getTime() < expiresAt;
};

export const isPasswordUser = (state: AuthState) => {
  const idTokenPayload = state.tokens && state.tokens.idTokenPayload;
  return idTokenPayload && idTokenPayload.sub.startsWith('auth0');
};

export type ContextAccount = {
  id?: string; // new key id for context, will replace all the above
  context: 'team' | 'user' | 'limited';
  accountNumber?: string | number;
  isPrivate: boolean;
  isLimitedAccess: boolean;
};

export interface ContextAccountPros {
  ctx?: ContextAccount;
}

/**
 * Use to get the context of the user according to the URL or the
 * current context set in the store.
 *
 * When using the result of this function in a `useEffect()` call,
 * pass the `user.context` object to the list of deps.
 *
 * ```
 *  const user = useUser();
 *  const ctx = contextAccount(store);
 *
 *  const _getTeam = async () => {
 *     if (ctx?.context === 'team') {
 *       await dispatch(getTeamByAccount(ctx.accountNumber));
 *       dispatch(getTeamAccount(ctx.accountNumber));
 *     }
 *   };
 *
 *   useEffect(() => {
 *     _getTeam();
 *   }, [user.context]); // eslint-disable-line
 * ```
 *
 * There are 3 ways to set the context, and in the following order:
 *
 * 1. URL param in the form of `/u/{accountNumber}/*` (only team accounts).
 * 2. URL query argument in the form of `?u=[team|user]-{accountNumber}`.
 * 3. User context already set in the Redux store. This context is also
 *    synchronized by Redux in the sessionStorage with the key `persist:account`.
 */
export const contextAccount = (
  store: Store<EmptyObject & RootState>
): ContextAccount | undefined => {
  const match: { params: { account: string } } | null = matchPath(window.location.pathname, {
    path: '/u/:account',
    exact: false,
  });
  if (match) {
    return readContextAccount(match.params.account);
  }
  const accountParam = new URLSearchParams(document.location.search).get('u');
  if (accountParam) {
    return readContextAccount(accountParam);
  }
  const state = store.getState();
  const accountContext = state.account.context;
  const profile = state.user.profile;
  if (accountContext) {
    const context = accountContext.context;
    const accountNumber = accountContext.accountNumber;
    let id;
    let isPrivate;
    let isLimitedAccess;
    if (context === 'team') {
      isPrivate = !!accountContext.isPrivate;
      isLimitedAccess = accountContext.isLimitedAccess;
      if (profile) {
        const team = profile.teams.find((t) => t.accountNumber === accountNumber);
        if (isLimitedAccess) {
          id = accountContext.id;
        } else if (team) {
          id = isPrivate ? team.contextIdPrivate! : team.contextId;
        }
      }
    } else {
      isPrivate = false;
      isLimitedAccess = false;
      if (profile) {
        id = profile.contextId;
      }
    }

    return {
      id,
      context,
      accountNumber,
      isPrivate,
      isLimitedAccess,
    };
  }
};

export const readContextAccount = (ctx?: string): ContextAccount | undefined => {
  if (ctx) {
    const found = ctx.match(
      /^((?<context>team|user|limited)-)?(?<ctxId>\d+)((?<isPrivate>)-private)?$/
    );
    if (found) {
      const { context, ctxId, isPrivate } = found.groups as any;
      const isLimitedAccess = context === 'limited';
      return {
        [isLimitedAccess ? 'id' : 'accountNumber']: ctxId,
        context: context === 'user' ? 'user' : 'team', // context is undefined or (team,limited) => team
        isPrivate: isPrivate === '',
        isLimitedAccess,
      };
    }
  }
};

export const parseContextAccount = (ctx?: ContextAccount, shortVersion = false): string => {
  if (ctx) {
    let context = '';
    if (!shortVersion || ctx.context !== 'team') {
      context = `${ctx.context}-`; // e.g. "111245098-private"
    }
    let ctxId: string | number;
    if (ctx.context === 'limited') {
      ctxId = ctx.id!;
    } else {
      ctxId = ctx.accountNumber!;
    }
    return `${context}${ctxId}${ctx.isPrivate ? '-private' : ''}`; // e.g. "team-111245098-private"
  }
  return '';
};

export const setLastContextAccount = (ctx: ContextAccount) => {
  storeAccountKey(ctx?.accountNumber ?? 'unknownUser', 'lastContextAccount', {
    ...ctx,
    created: new Date().getTime(),
  });
};

export const getLastContextAccount = (
  accountNumber: string | number
): ContextAccount | undefined => {
  return getAccountStoredKey(accountNumber, 'lastContextAccount');
};

export const removeLastContextAccount = (accountNumber: string | number) => {
  return removeAccountKey(accountNumber, 'lastContextAccount');
};
