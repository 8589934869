export interface DroppedFiles {
  name: string;
  guest_paths: string;
  size: string;
  type: string;
  sha256: string;
  md5: string;
  sha1: string;
  sha3_384: string;
  ssdeep: string;
  tlsh: string;
  data: string;
}

export interface IRequests {
  http_request: [{ method: string; request: string; url: string }];
  http_response: [{ response: string; status: string }];
}

export interface SandboxTriageData {
  network: ISandboxData['tool_metadata']['network'];
  requests: IRequests[];
}

export interface ISandboxData {
  tool_metadata: {
    network: {
      hosts?: {
        ip: string;
      }[];
      domains?: {
        domain: string;
      }[];
      dns?: {
        request: string;
      }[];
      dead_hosts?: string[];
    };
    dropped: DroppedFiles[];
    ttp: string[];
  };
}

export interface IJarmData {
  tool_metadata: {
    hosts: {
      host: string;
      port: string;
      jarm: string;
    }[];
  };
}

export interface ITriageData {
  tool_metadata: {
    extracted_c2_ips: string[];
    targets: {
      iocs: {
        domains: string[];
        ips: string[];
        urls: string[];
      };
    }[];
    extracted: {
      config: {
        c2: string[];
        attr: {
          autorun: {
            control: string;
            name: string;
          };
          autostart_method: string;
          install_path: string;
          registry_keyname: string;
          watchdog_path: string;
          taskscheduler_taskname: string;
        };
      };
    }[];
  };
}

export enum SandboxInstanceStatus {
  PENDING = 'PENDING',
  STARTED = 'STARTED',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  TIMEDOUT = 'TIMEDOUT',
  DELAYED = 'DELAYED',
  FAILED_REIMBURSED = 'FAILED_REIMBURSED',
  TIMEDOUT_REIMBURSED = 'TIMEDOUT_REIMBURSED',
  COLLECTING_DATA = 'COLLECTING_DATA',
}

export enum SandboxProviders {
  CAPE = 'cape',
  TRIAGE = 'triage',
}

type SandboxConfigAbstract = {
  [key: string]: any;
};

export enum SandboxArtifactTypes {
  ROOT = 'root',
  SANDBOX_ARTIFACT = 'Sandbox Artifact',
  JSON_REPORT = 'JSON Report', // this combines => REPORT & RAW_REPORT
  DROPPED_FILE = 'dropped_file',
  SCREENSHOT = 'screenshot',
  PCAP = 'pcap',
  JARM = 'jarm',
}

export type SandboxConfig = {
  provider: SandboxConfigAbstract;
  vm: SandboxConfigAbstract;
  capeMalscore: number;
  traigeAnalysisScore: number;
  triageStaticScore: number;
  networkEnabled: boolean;
  browser?: string;
  artifactType: string;
};

export interface SandboxArtifact {
  created: string;
  extendedType: string;
  id: string;
  instanceId: string;
  mimetype: string;
  name: string;
  sandboxTaskId: string;
  type: string;
}

export interface Sandbox<T> {
  accountNumber: string | null;
  community: string;
  config: SandboxConfig;
  created: string;
  expiration: string;
  id: string;
  instanceId: string;
  sandbox: SandboxProviders;
  sandboxArtifacts: SandboxArtifact[];
  sha256: string;
  status: SandboxInstanceStatus;
  teamAccountNumber: null | string;
  contextAccount?: string;
  uploadUrl: null | string;
  report: T | SandboxTriageData;
}

export type SandboxReport = {
  [key: string]: any;
};

export type StatusSummaryType =
  | SandboxInstanceStatus.DELAYED
  | SandboxInstanceStatus.FAILED_REIMBURSED
  | SandboxInstanceStatus.TIMEDOUT_REIMBURSED;

export const STATUS_SUMMARY: { [key in StatusSummaryType]: string } = {
  [SandboxInstanceStatus.DELAYED]:
    'Retry sandboxing if needed. If failed or timed out, quota used will be reimbursed.',
  [SandboxInstanceStatus.FAILED_REIMBURSED]:
    'Quota used has been reimbursed due to a failure.\nPlease retry sandboxing.',
  [SandboxInstanceStatus.TIMEDOUT_REIMBURSED]:
    'Quota used has been reimbursed due to a timed out.\nPlease retry sandboxing.',
};

export const getStatusSummary = (
  status: SandboxInstanceStatus,
  failedReason?: string
): string => {
  let statusMessage = STATUS_SUMMARY[status as keyof typeof STATUS_SUMMARY] ?? '';
  if (failedReason) {
    if (!failedReason.endsWith('.')) {
      failedReason += '.';
    }
    statusMessage = `${failedReason}\n${statusMessage}`;
  }
  return statusMessage;
};
