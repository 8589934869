import React, { Fragment } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import { FormattedMessage } from 'react-intl';
import { Team, ETeamRole, TeamMember } from 'models/Team';
import { filterTeamMembersWithRole, filterTeamMembersWithoutRole } from 'state/team/selectors';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Tooltip from '@material-ui/core/Tooltip';
import AvatarSidebar from 'views/components/layout/Sidebar/AvatarSidebar';
import ContextMenu from 'views/components/menu/ContextMenu';
import UserBadge from 'views/components/Avatar/UserBadge';
import { UserAvatar } from 'views/components/Avatar';
import ability from 'config/ability';
import { Community } from '../../../../models/Community';

interface Props {
  className?: string;
  team: Team;
  community: Community | null;
  members: TeamMember[];
  accountNumber: string;
  onTeamEdit: () => void;
  onTeamArchive: () => void;
  shrink?: boolean;
}

const TeamDetailSidebar = ({
  className,
  team,
  community,
  members,
  onTeamEdit,
  onTeamArchive,
  accountNumber,
  shrink = false,
}: Props) => {
  const { classes } = useStyles();

  return (
    <AvatarSidebar
      shrink={shrink}
      className={className}
      heading={team.name}
      text={`#${team.accountNumber}`}
      teamName={team.name}
      accountNumber={accountNumber}
      renderContextMenu={
        ability.can('manage', 'TeamConfigurations')
          ? () => (
              <ContextMenu
                testId='teamDetailSidebarBtn'
                options={[
                  {
                    label: 'Edit',
                    type: 'primary',
                    onClick: () => onTeamEdit(),
                    isShown: true,
                  },
                  {
                    label: 'Delete',
                    type: 'danger',
                    onClick: () => onTeamArchive(),
                    isShown: true,
                  },
                ]}
              />
            )
          : undefined
      }
    >
      <p className={!shrink ? 'h-mt-tiny h-mb-xs p1' : 'p1'}>{team.description}</p>
      {!shrink && community && (
        <Fragment>
          <div className='h-mb-tiny' css={classes.title}>
            <FormattedMessage id='teams.community' defaultMessage='Private Community' />
          </div>
          <div className='h-mb-xxs'>{community}</div>
        </Fragment>
      )}
      {!shrink && <TeamAdmins members={filterTeamMembersWithRole(members, ETeamRole.teamAdmin)} />}
      {!shrink && (
        <TeamMembers
          members={filterTeamMembersWithoutRole(
            filterTeamMembersWithoutRole(members, ETeamRole.teamAdmin),
            ETeamRole.teamOwner
          )}
        />
      )}
    </AvatarSidebar>
  );
};

const TeamAdmins = ({ members }: { members: TeamMember[] }) => {
  const { classes } = useStyles();
  if (!members || members.length === 0) {
    return null;
  }
  return (
    <div data-cy='teamAdmins'>
      <div className='h-mb-tiny' css={classes.title}>
        <FormattedMessage id='teams.admins' defaultMessage='Team admins' />
      </div>
      {members.map((member) => (
        <div key={member.accountNumber} className='h-mb-xxs'>
          <UserBadge
            css={classes.avatarAdmin}
            name={member.name}
            accountNumber={`${member.accountNumber}`}
            role={ETeamRole.teamAdmin}
          />
        </div>
      ))}
    </div>
  );
};

const TeamMembers = ({ members }: { members: TeamMember[] }) => {
  const { classes } = useStyles();

  const displayedMembers = members.slice(0, 14);
  const hiddenMemberCount = members.length > 14 && `+${members.length - 14}`;

  return members && members.length > 0 ? (
    <div className='h-mt-xs'>
      <div className='h-mb-tiny' css={classes.title}>
        <FormattedMessage id='teams.members' defaultMessage='Team members' />
      </div>
      <div css={classes.teamMemberWrap}>
        {displayedMembers.map((member) => (
          <Tooltip key={member.accountNumber} title={member.name} placement='bottom-end'>
            <div>
              <UserAvatar
                accountNumber={`${member.accountNumber}`}
                css={classes.avatar}
                name={member.name}
              />
            </div>
          </Tooltip>
        ))}
        {hiddenMemberCount && <div css={classes.memberCount}>{hiddenMemberCount}</div>}
      </div>
    </div>
  ) : null;
};

const useStyles = makeStyles({
  base: {
    avatarAdmin: {
      fontSize: '0.26rem',
    },
    avatar: {
      fontSize: '0.26rem !important',
      margin: '5px 5px 0 0',
    },
    title: {
      fontWeight: styles.font.weight.bold,
    },
    teamMemberWrap: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    memberCount: {
      display: 'flex',
      alignItems: 'center',
      fontSize: styles.font.size.p1,
      fontWeight: styles.font.weight.bold,
      marginLeft: 5,
    },
  },
  light: {
    title: {
      color: styles.color.black,
    },
    memberCount: {
      color: styles.color.purple,
    },
  },
  dark: {
    title: {
      color: styles.color.white,
    },
    memberCount: {
      color: styles.color.xLightGrey,
    },
  },
});
export default TeamDetailSidebar;
