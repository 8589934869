import React, { Fragment } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { defineMessages, useIntl } from 'react-intl';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import SEO from 'views/components/layout/SEO';
import BaseLayout from 'views/components/layout/BaseLayout';
import Loader from 'views/components/Loader';
import { FileUpload } from 'views/components/FileUpload';
import Card from '@material-ui/core/Card';
import MalwareTable from './MalwareTable';
import styled from '@emotion/styled';
import styles from 'views/styles';
import { useSystem } from 'views/components/providers/SystemProvider';
import { Notification } from 'views/components/Notification/Notification';
import { useUser } from 'views/components/providers/UserProvider';
import { contextAccount } from 'state/auth/selectors';
import { store } from 'state/store';
import useHasFeature from 'hooks/useHasFeature';

const StyledCard = styled(Card)`
  padding: ${styles.spacing.md} ${styles.spacing.xl};
  margin: ${styles.spacing.sm};
`;

const StyledLoader = styled(Loader)`
  margin: 0 auto;
  text-align: center;
  position: relative;
  top: calc(100vh / 4);
`;

const messages = defineMessages({
  notificationText: {
    id: 'homePage.notification.text',
    defaultMessage: 'NectarNet Rewards Program is Live',
  },
  notificationLink: {
    id: 'homePage.notification.link',
    defaultMessage: 'Learn more',
  },
});

const HomePage = () => {
  const { classes } = useStyles();
  const user = useUser();
  const intl = useIntl();
  const ctx = contextAccount(store);
  const { loading: featureLoading, hasFeature: hasScanFeature } = useHasFeature('scan');

  const hasLimitedAccess = user.context?.isLimitedAccess;

  const { isMaintenanceMode } = useSystem();
  return (
    <Fragment>
      <SEO />
      <BaseLayout>
        {featureLoading ? (
          <StyledLoader />
        ) : hasLimitedAccess && !hasScanFeature ? (
          <div className='container'>
            <div className='col-12'>
              <StyledCard>
                <div css={classes.container}>
                  <h2 className='h2 h-mb-xxs'>Limited Access Context</h2>
                  <div>
                    <p>You have been granted limited access to this account.</p>
                    <p>
                      Please use the navigation at the top to select the task you wish to perform.
                    </p>
                  </div>
                </div>
              </StyledCard>
              {!Boolean(ctx?.isPrivate) && !isMaintenanceMode && (
                <StyledCard>
                  <MalwareTable />
                </StyledCard>
              )}
            </div>
          </div>
        ) : (
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <StyledCard>
                  <FileUpload isScan />
                </StyledCard>
                {!Boolean(ctx?.isPrivate) && !isMaintenanceMode && (
                  <StyledCard>
                    <MalwareTable />
                  </StyledCard>
                )}
              </div>
            </div>
          </div>
        )}
      </BaseLayout>
      {!Boolean(ctx?.isPrivate) && (
        <Notification id='REWARDS_LIVE' show={user.id !== -1 && !user.context?.isLimitedAccess}>
          {intl.formatMessage(messages.notificationText)}&nbsp;&nbsp;
          <a className='a' href='/account/rewards'>
            {intl.formatMessage(messages.notificationLink)}
          </a>
        </Notification>
      )}
    </Fragment>
  );
};

const useStyles = makeStyles({
  base: {
    container: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      gap: '3rem',
      flexGrow: 1,
    },
  },
  light: {
    container: {},
  },
  dark: {
    container: {},
  },
});
export default HomePage;
