import { sortBy, concat } from 'lodash';
import { AccountPlan, EAccountPlans } from 'models/Account';
import { AuthAction, AuthActionName } from 'state/auth/types';
import { AccountAction, AccountActionName } from './types';
import { AccountState } from '.';
import { AccountPlansRes, AccountPlanRes } from 'services/api/schema/account';
import { CUSTOM_PLANS } from 'views/pages/PricingPage/PricingPanel/PricingComparePlans/plans';

export const initialState: AccountState = {
  plans: [],
  context: null,
};

const account = (
  state: AccountState = initialState,
  action: AccountAction | AuthAction
): AccountState => {
  switch (action.type) {
    case AccountActionName.GET_ACCOUNT_PLANS: {
      if (!action.result) {
        return state;
      }
      return {
        ...state,
        plans: concat(transformPlanData(action.result), CUSTOM_PLANS),
      };
    }

    case AccountActionName.SWITCH_ACCOUNT:
    case AccountActionName.GET_ACCOUNT_CONTEXT: {
      if (!action.result) {
        return state;
      }
      let isPrivate;
      if (action.result.context === 'user') {
        isPrivate = false;
      } else if (action.result.isPrivate !== null && action.result.isPrivate !== undefined) {
        isPrivate = action.result.isPrivate;
      } else {
        isPrivate = state.context?.isPrivate === true;
      }
      return {
        ...state,
        plans: [],
        context: {
          ...action.result,
          isPrivate,
          isLimitedAccess: action.result.isLimitedAccess,
        },
      };
    }

    case AuthActionName.LOGOUT: {
      return {
        ...initialState,
        context: {
          ...state.context!,
        },
      };
    }

    default: {
      return state;
    }
  }
};

const isEnterprisePlan = (plan: string) => {
  return [EAccountPlans.enterprise, EAccountPlans.enterprisePremium].includes(
    plan as EAccountPlans
  );
};

export const transformPlanData = (plans: AccountPlansRes): AccountPlan[] => {
  if (!plans) {
    return [];
  }

  const getFeatureValueByTag = (plan: AccountPlanRes, tag: string) => {
    const feature = plan.features.find((item) => item.tag === tag);

    if (feature) {
      return feature.value;
    }

    return false;
  };

  return sortBy(
    plans.map((plan) => ({
      name: plan.name,
      isPrivate: plan.isPrivate,
      features: {
        apiRequestLimit: { value: plan.dailyApiLimit, units: 'day' },
        scans: { value: plan.scansPerMonth, units: 'month' },
        downloads: { value: plan.downloadsPerMonth, units: 'month' },
        hashSearches: { value: plan.hashSearchPerMonth, units: 'month' },
        metadataSearches: { value: plan.metadataSearchPerMonth, units: 'month' },
        monthlyApiLimit: { value: plan.monthlyApiLimit!, units: 'month' },
        liveHunts: {
          value: plan.name === EAccountPlans.community ? false : plan.liveHuntPerMonth,
          units: 'active',
        },
        historicalHunts: {
          value: plan.name === EAccountPlans.community ? false : plan.historicalHuntPerMonth,
          units: 'month',
        },
        huntingYaraRules: {
          value: getFeatureValueByTag(plan, 'hunting_yara_rules') ? -1 : false,
        },
        sandboxing: {
          value: isEnterprisePlan(plan.name),
        },
        sandboxRequest: {
          value: isEnterprisePlan(plan.name),
        },
        sandboxSearch: {
          value: isEnterprisePlan(plan.name),
        },
        hunting: { value: false },
        hasStream: { value: false },
        hasStreamAccess: {
          value: getFeatureValueByTag(plan, 'has_stream_access'),
        },
        taxii: { value: getFeatureValueByTag(plan, 'taxii') },
        additionalFeatures: { value: false },
        hasSupport: { value: getFeatureValueByTag(plan, 'has_support') },
        privateComm: { value: getFeatureValueByTag(plan, 'private_comm') },
        createTeams: { value: getFeatureValueByTag(plan, 'create_teams') },
        integrationsOk: {
          value:
            [EAccountPlans.enterprise, EAccountPlans.enterprisePremium].includes(
              plan.name as any
            ) || 'feature.limited',
        },
        price: { value: plan.price, units: 'usd' },
      },
      interval: plan.interval,
    })),
    [(plan) => plan.features.scans.value]
  );
};

export default account;
