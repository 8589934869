import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import styles from 'views/styles';
import { Tooltip } from '@material-ui/core';
import { useUser } from 'views/components/providers/UserProvider';
import Card from 'views/components/layout/Card';
import { TeamAvatar } from 'views/components/Avatar';

interface Props {
  className?: string;
  heading: string;
  text?: string | null;
  teamName: string;
  accountNumber: string;
  renderContextMenu?: () => JSX.Element;
  children: React.ReactNode;
  shrink?: boolean;
}

const AvatarSidebar = ({
  className,
  heading,
  text,
  teamName,
  accountNumber,
  renderContextMenu,
  children,
  shrink,
}: Props) => {
  const user = useUser();

  const isPrivateCommunity = user.context?.isPrivate;

  return (
    <Card className={!shrink ? className : ''} css={[style.card, shrink && style.shrinkCard]}>
      <Tooltip
        interactive
        title={
          shrink ? (
            <div css={style.shrinkCardInner}>
              <h4 style={{ color: 'unset' }} className='h4' data-cy='avatarSidebarHeading'>
                {heading}
              </h4>
              <p className='p1' data-cy='avatarSidebarText'>
                {text}
              </p>
              {children}
            </div>
          ) : (
            ''
          )
        }
        placement='right'
      >
        <div style={{ margin: '0 auto' }}>
          <TeamAvatar
            shrink={shrink}
            isPrivate={!!isPrivateCommunity}
            accountNumber={accountNumber}
            teamName={teamName}
          />
        </div>
      </Tooltip>
      {!shrink && (
        <div css={style.cardInner}>
          <div css={style.titleWrap}>
            <h4 className='h4' data-cy='avatarSidebarHeading'>
              {heading}
            </h4>
            {typeof renderContextMenu === 'function' && renderContextMenu()}
          </div>
          <p className='h-mt-tiny h-mb-xs p1' data-cy='avatarSidebarText'>
            {text}
          </p>
          {children}
        </div>
      )}
    </Card>
  );
};

const style = {
  card: css`
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-height: 350px;
    padding: ${styles.spacing.sm} 0;
  `,
  shrinkCard: css`
    min-height: unset;
    padding: 28px 16px;
    margin-bottom: 1px;
    border-radius: 6px 6px 0 0;
    background: #353154;
  `,
  cardInner: css`
    margin-top: 8%;
    flex: 1 0 auto;
    padding: 0 ${styles.spacing.xs} ${styles.spacing.sm};
    h3 {
      font-size: 2.4rem;
    }
  `,
  shrinkCardInner: css`
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding: 12px 8px;
    text-align: center;
  `,
  titleWrap: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
};

export default AvatarSidebar;
