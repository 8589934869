import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { DATE_TIME_FORMAT } from 'utils/constants';
import { Page } from 'models/Page';
import { TeamMemberInvitation } from 'models/Team';
import FabButton from 'views/components/Button/FabButton';
import InfiniteScrollTable, {
  IColumn,
  IDataRenderer,
  EColumnAlign,
  IColumnSort,
} from 'views/components/table/InfiniteScrollTable';
import { btnMessages } from 'views/components/Button';
import ActionBtn from 'views/components/table/ActionBtn';
import { format as formatDate, parseISO as parseDate, isPast } from 'date-fns';

interface ITeamInvitationsTable {
  invitations: Page<TeamMemberInvitation>;
  sort?: IColumnSort<keyof TeamMemberInvitation>;
  onSort: (orderBy: keyof TeamMemberInvitation) => void;
  onInviteTeamMember: () => void;
  onArchiveTeamInvitation: (invitationId: number) => void;
  onResendTeamInvitation: (invitationId: number) => void;
  onLoadMore?: () => Promise<void>;
}

const messages = defineMessages({
  email: {
    id: 'table.row.email',
    defaultMessage: 'Email',
  },
  expired: {
    id: 'invitation.state.expired',
    defaultMessage: 'Expired',
  },
  expiresAt: {
    id: 'teamInvitations.tableHeader.expriesAt',
    defaultMessage: 'Expires',
  },
  pending: {
    id: 'invitation.state.pending',
    defaultMessage: 'Pending',
  },
  accepted: {
    id: 'invitation.state.accepted',
    defaultMessage: 'Accepted',
  },
  declined: {
    id: 'invitation.state.declined',
    defaultMessage: 'Declined',
  },
});

type TStatus = 'email' | 'expired' | 'expiresAt' | 'pending' | 'accepted' | 'declined';

const TeamInvitationsTable = ({
  invitations,
  sort,
  onSort,
  onInviteTeamMember,
  onArchiveTeamInvitation,
  onResendTeamInvitation,
  onLoadMore,
}: ITeamInvitationsTable) => {
  const { formatMessage } = useIntl();

  const columns: IColumn<keyof TeamMemberInvitation>[] = [
    {
      id: 'name',
      label: 'Name',
      numeric: false,
      sortable: true,
    },
    {
      id: 'email',
      label: 'Email',
      numeric: false,
      sortable: false,
    },
    {
      id: 'createdAt',
      label: 'Created',
      numeric: false,
      sortable: true,
      dataRenderer: ({ cellData }: IDataRenderer<TeamMemberInvitation>) => {
        return cellData ? formatDate(parseDate(cellData), DATE_TIME_FORMAT) : '';
      },
    },
    {
      id: 'expiresAt',
      label: 'Expires',
      numeric: false,
      sortable: true,
      dataRenderer: ({ cellData }: IDataRenderer<TeamMemberInvitation>) => {
        return cellData ? formatDate(parseDate(cellData), DATE_TIME_FORMAT) : '';
      },
    },
    {
      id: 'state',
      label: 'Status',
      numeric: false,
      sortable: true,
      dataRenderer: ({ cellData, rowData }: IDataRenderer<TeamMemberInvitation>) => {
        const expired = isPast(parseDate(rowData.expiresAt));
        return formatMessage(expired ? messages.expired : messages[cellData as TStatus]);
      },
    },
    {
      id: 'actions',
      label: 'Actions',
      align: EColumnAlign.RIGHT,
      width: -100,
      dataRenderer: ({ rowData }: IDataRenderer<TeamMemberInvitation>) => {
        return (
          <>
            <ActionBtn
              style={{ marginLeft: 8 }}
              name='switch'
              aria-label={formatMessage(btnMessages.resend)}
              onClick={() => onResendTeamInvitation(rowData.id)}
              data-cy='resendTeamInvitationBtn'
            />
            <ActionBtn
              style={{ marginLeft: 8 }}
              name='delete'
              aria-label={formatMessage(btnMessages.delete)}
              data-cy='deleteTeamInvitationBtn'
              onClick={() => onArchiveTeamInvitation(rowData.id)}
            />
          </>
        );
      },
    },
  ];

  return (
    <InfiniteScrollTable<TeamMemberInvitation>
      columns={columns}
      data={invitations.results}
      sort={sort}
      onSort={onSort}
      onLoadMore={onLoadMore}
      loadMoreTreshold={5}
      hasMore={invitations.next_page}
      button={<FabButton icon='plus' onClick={onInviteTeamMember} testId='inviteTeamMemberFab' />}
    />
  );
};

export default TeamInvitationsTable;
