import React, { FC } from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { USER_AVATAR_URL, TEAM_AVATAR_URL, COMMUNITY_AVATAR_URL } from 'utils/constants';
import { useTheme } from 'views/components/providers/ThemeProvider';
import { Variant, variants } from 'views/components/ThemePreview';
import usePrivateCommunityTheme from 'hooks/usePrivateCommunityTheme';

interface AvatarProps {
  className?: string;
  shrink?: boolean;
}

interface UserAvatarProps extends AvatarProps {
  name: string;
  accountNumber: string;
}

interface TeamAvatarProps extends AvatarProps {
  teamName: string;
  accountNumber: string;
  isPrivate: boolean;
}

interface CommunityAvatarProps extends AvatarProps {
  communityName: string;
}

const Avatar: FC<AvatarProps> = ({ className, children, shrink }) => {
  return (
    <div className={className} css={[style.avatar, shrink && style.shrinkAvatar]}>
      {children}
    </div>
  );
};

export const UserAvatar = ({ name, accountNumber, ...rest }: UserAvatarProps) => {
  return (
    <Avatar css={style.userAvatar} {...rest}>
      <img src={`${USER_AVATAR_URL}/${accountNumber}`} alt={`${name}`} />
    </Avatar>
  );
};

export const TeamAvatar = ({ isPrivate, teamName, accountNumber, ...rest }: TeamAvatarProps) => {
  const theme = useTheme();
  const { variant } = usePrivateCommunityTheme(isPrivate);
  const variantTheme = variant as Exclude<
    Variant,
    'salmon-dark' | 'forest-dark' | 'mustard-dark' | 'default-dark'
  >;

  const themeColor = theme === 'light' ? variants[variantTheme] : variants[`${variantTheme}-dark`];

  const modifiedThemeColor =
    isPrivate &&
    !!variant &&
    themeColor.background
      .split('')
      .filter((char: string) => char !== '#')
      .join('');

  const color =
    isPrivate && !!variant && modifiedThemeColor ? `?themeColor=${modifiedThemeColor}` : '';
  const AVATAR_URL = encodeURI(`${TEAM_AVATAR_URL}/${accountNumber}${color}`);

  return (
    <Avatar css={style.userAvatar} {...rest}>
      <img src={AVATAR_URL} alt={teamName} />
    </Avatar>
  );
};

export const CommunityAvatar = ({ communityName, ...rest }: CommunityAvatarProps) => (
  <Avatar css={style.userAvatar} {...rest}>
    <img src={encodeURI(`${COMMUNITY_AVATAR_URL}/${communityName}`)} alt={communityName} />
  </Avatar>
);

const style = {
  avatar: css`
    font-size: 1rem;
    width: 14em;
    align-self: center;
  `,
  shrinkAvatar: css`
    width: 4em;
  `,
  userAvatar: css`
    > img {
      width: 100%;
      display: block;
      position: relative;
      border-radius: 100%;
    }
  `,
};
