import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import Loader from 'views/components/Loader';

interface IPanelLoaderProps {
  className?: string;
}

const PanelLoader = ({ className }: IPanelLoaderProps) => (
  <div css={style.loaderSpace} className={className}>
    <div>
      <Loader css={style.loader} />
    </div>
  </div>
);

const style = {
  loaderSpace: css`
    min-height: 70rem;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  loader: css`
    font-size: 0.75rem;
  `,
};

export default PanelLoader;
