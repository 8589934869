import React, { useCallback, useMemo, Fragment, useState, useEffect } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import SEO from 'views/components/layout/SEO';
import BaseLayout from 'views/components/layout/BaseLayout';
import Card from 'views/components/layout/Card';
import Icon from 'views/components/Icon';
import useHasFeature from 'hooks/useHasFeature';
import { makeStyles } from 'views/components/providers/ThemeProvider';

const featureDescriptions = {
  hash_search:
    'Hash searching allows you to gain insight by finding previously submitted artifacts by its hash.',
  metadata_search:
    'Metadata searching allows you to gain insight by finding previously submitted artifacts according to its metadata.',
  ioc_user:
    'IOC searching allows you to find the IOCs associated with a hash OR find the hashes associate with IOCs.',
  hunting_yara_rules: 'YARA Rules used for Live and Historical Hunting in the PolySwarm network.',
  live_hunt:
    'Live Hunting will match your active YARA ruleset against new artifacts as they are submitted.',
  historical_hunt:
    'Historical Hunting will match your YARA ruleset against previously submitted artifacts.',
  sandbox_search: 'Submit a File to the Sandbox.',
  sandbox_request: 'Search Sandbox results',
};

function LimitedAccessLanding() {
  const { classes } = useStyles();
  const { features, loading } = useHasFeature();
  const featureTags = features.map((feature) => feature.tag);

  return (
    <Fragment>
      <SEO title='Limited Access Context' />
      <BaseLayout variant={undefined}>
        <div css={classes.container}>
          <Card css={classes.card}>
            <h2 css={classes.title}>Limited Access Context</h2>
            <Icon name='visibility-lock' css={classes.icon} />
            <p css={classes.message}>
              You have been granted limited access to this account. Please use the navigation at the
              top to select the task you wish to perform.
            </p>
          </Card>
          <Card css={classes.card}>
            <h3 css={classes.smallTitle}>Available Features</h3>
            <div css={classes.features}>
              {!loading &&
                Object.keys(featureDescriptions)
                  .filter((feature) => featureTags.includes(feature))
                  .map((feature) => (
                    <div>
                      <h4 css={classes.featureTitle}>
                        {feature
                          .replace(/_/g, ' ')
                          .split(' ')
                          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                          .join(' ')}
                      </h4>
                      <p css={classes.featureDescription}>
                        {featureDescriptions[feature as keyof typeof featureDescriptions]}
                      </p>
                    </div>
                  ))}
            </div>
            <p css={classes.info}>
              To learn more about each feature available visit{' '}
              <a
                css={classes.link}
                href='https://docs.polyswarm.io/consumers'
                target='_blank'
                rel='noreferrer'
              >
                https://docs.polyswarm.io/consumers
              </a>{'.'}
            </p>
          </Card>
        </div>
      </BaseLayout>
    </Fragment>
  );
}

const useStyles = makeStyles({
  base: {
    container: {
      padding: '4rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: '4rem',
    },
    card: {
      padding: '4rem',
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      textAlign: 'center',
      fontSize: '3rem',
    },
    smallTitle: {
      textAlign: 'center',
      fontSize: '2.5rem',
    },
    featureTitle: {
      color: 'var(--main)',
      fontSize: '1.5rem',
      fontWeight: 600,
    },
    featureDescription: {
      fontSize: '1.5rem',
      marginTop: '0.35rem',
    },
    features: {
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
      marginBottom: '2.5rem',
      marginTop: '2.5rem',
    },
    message: {
      textAlign: 'center',
      fontSize: '1.5rem',
    },
    info: {
      fontSize: '1.5rem',
    },
    link: {
      color: 'var(--main)',
    },
    icon: {
      color: 'var(--main)',
      alignSelf: 'center',
      fontSize: '3rem',
      marginTop: '2rem',
      marginBottom: '2.5rem',
    },
  },
  dark: {},
});

export default LimitedAccessLanding;
