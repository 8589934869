import { FC, Fragment } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Router, Switch, Redirect } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { store, persistor, history } from 'state/store';
import GlobalDownloadProvider from 'views/components/providers/GlobalDownloadProvider';
import PivotingProvider from 'views/components/providers/PivotingProvider';
import LocaleProvider from 'views/components/providers/LocaleProvider';
import AuthProvider from 'views/components/providers/AuthProvider';
import SystemProvider from 'views/components/providers/SystemProvider';
import UserProvider, { useUser } from 'views/components/providers/UserProvider';
import { ThemeProviderContainer as ThemeProvider } from 'views/components/providers/ThemeProvider';

import ProtectedRoute from 'views/components/auth/ProtectedRoute';
import GlobalStyles from 'views/components/GlobalStyles';
import AnalyticsProvider from 'views/components/providers/AnalyticsProvider';
import IconBase from 'views/components/Icon/IconBase';
import { Notification } from 'views/components/Banner/Notification';
import ModalConductor from 'views/components/Modal/ModalConductor';

import { AuthCallbackPage } from 'views/pages/AuthCallbackPage';
import { EmailVerificationPage } from 'views/pages/EmailVerifiactionPage';
import { LoginPage } from 'views/pages/LoginPage';
import NotLoggedInPage from 'views/pages/NotLoggedInPage/NotLoggedInPage';
import RegionRedirect from 'views/pages/RegionRedirect/RegionRedirect';
import HomePage from 'views/pages/HomePage';
import ScanPage from 'views/pages/ScanPage';
import HowItWorksPage from 'views/pages/HowItWorksPage';
import ScanRoutes from 'views/pages/ScanPage/ScanRoutes';
import MicroenginesRoutes from 'views/pages/MicroenginesPage/MicroenginesRoutes';
import SearchPage from 'views/pages/SearchPage';
import HuntPage from 'views/pages/HuntPage';
import PricingPage from 'views/pages/PricingPage';
import PrivacyPage from 'views/pages/PrivacyPage';
import AccountPage from 'views/pages/AccountPage';
import MarketplaceStatsPage from 'views/pages/MarketplaceStatsPage';
import { TeamDetailPage } from 'views/pages/TeamDetailPage';
import PageNotFound from 'views/pages/PageNotFound';
import TermsPage from 'views/pages/TermsPage';
import CustomerTermsPage from 'views/pages/CustomerTermsPage';
import AccessibilityPage from 'views/pages/AccessibilityPage';
import EngineAgreementPage from 'views/pages/EngineAgreementPage';
import SilobreakerPage from 'views/pages/IntegrationsPage/SilobreakerPage';
import SandboxPage from 'views/pages/SandboxPage/SandboxPage';
import SandboxPanel from 'views/pages/SandboxPage/SandboxPanel';
import SandboxDetails from 'views/pages/SandboxPage/SandboxDetails';
import ManualAnalysis from 'views/pages/ManualAnalysis';
import LimitedAccessLanding from 'views/pages/LimitedAccessLanding';
import { ScrollJitter } from 'views/components/ScrollJitter';
import { SwitchAccountPage } from 'views/pages/SwitchAccountPage';
import { useIsPageEnabled } from 'hooks/useIsPageEnabled';

import tenant from 'tenants';
import { Pages } from 'tenants/config';
import { Route } from 'tenants/components';
import customPages from 'tenants/custom-pages';

const Providers: FC = ({ children }) => (
  <Provider store={store}>
    <HelmetProvider>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider>
          <Fragment>
            <GlobalStyles />
            <LocaleProvider>
              <Router history={history}>
                <AuthProvider>
                  <SystemProvider>
                    <UserProvider>
                      <GlobalDownloadProvider>
                        <PivotingProvider>
                          <AnalyticsProvider>{children}</AnalyticsProvider>
                        </PivotingProvider>
                      </GlobalDownloadProvider>
                    </UserProvider>
                  </SystemProvider>
                </AuthProvider>
              </Router>
            </LocaleProvider>
          </Fragment>
        </ThemeProvider>
      </PersistGate>
    </HelmetProvider>
  </Provider>
);

const routePageMap = {
  scan: ScanPage,
  search: SearchPage,
  hunt: HuntPage,
  sandbox: SandboxPage,
} as Partial<Record<Pages, React.ComponentType<any>>>;

const getTenantPage = (page: Pages | undefined, fallback: React.ComponentType<any>) => {
  if (!page) {
    return fallback;
  }
  if (typeof customPages[page] !== 'string') {
    return customPages[page] as React.ComponentType<any>;
  }
  return fallback;
};

const Routes = () => {
  const user = useUser();
  const page = useIsPageEnabled();
  const showManualAnalysis = !!user.manualAnalysisContextId;
  const hasLimitedAccess = user.context?.isLimitedAccess;

  if (page.loading) return null;
  return (
    <Switch>
      <Route exact path='/limited' component={LimitedAccessLanding} />
      <Route exact path='/region' component={RegionRedirect} />
      <Route exact path='/switch' component={SwitchAccountPage} />
      <Route exact path='/callback' component={AuthCallbackPage} />
      <Route exact path='/verification/email' component={EmailVerificationPage} />
      <Route exact path='/login' component={LoginPage} />
      <Route exact path='/try' render={() => <Redirect to='/' />} />
      <Route
        exact
        path='/'
        component={
          tenant.isLogingRequired && user.id === -1
            ? getTenantPage(tenant.notLoggedInLandingPage, NotLoggedInPage)
            : tenant.landingPage && page.isEnabled(tenant.landingPage)
            ? routePageMap[tenant.landingPage]
            : hasLimitedAccess
            ? LimitedAccessLanding
            : HomePage
        }
      />
      <Route
        exact
        path='/manual-analysis'
        show={page.isEnabled('manual-analysis') && showManualAnalysis}
        component={ManualAnalysis}
      />
      <Route
        exact
        path='/how-it-works'
        show={page.isEnabled('how-it-works')}
        component={HowItWorksPage}
      />
      <Route exact path='/privacy' show={page.isEnabled('privacy')} component={PrivacyPage} />
      <Route
        path='/scan'
        show={page.isEnabled('scan') || page.isEnabled('scan-results')}
        render={() => (
          <ScanRoutes
            scanEnabled={page.isEnabled('scan')!}
            scanResultsEnabled={page.isEnabled('scan-results')!}
          />
        )}
      />
      <Redirect exact from='/microengines' to='/engines' />
      <Redirect exact from='/microengine' to='/engines' />
      <Redirect exact from='/engine' to='/engines' />
      <Route path='/engines' show={page.isEnabled('engines')} component={MicroenginesRoutes} />
      <Route
        path='/marketplace-stats'
        show={page.isEnabled('marketplace-stats')}
        component={MarketplaceStatsPage}
      />
      <Route path='/integrations/silobreaker' component={SilobreakerPage} />
      <Route path='/search' show={page.isEnabled('search')} component={SearchPage} />
      <Route path='/hunt' show={page.isEnabled('hunt')} component={HuntPage} />
      <Route
        path='/sandbox/detail/:artifactType(file|url)/:sha256'
        render={(routeProps) => (
          <SandboxPage {...routeProps}>
            <SandboxDetails />
          </SandboxPage>
        )}
      />
      <Route
        show={page.isEnabled('sandbox')}
        path={['/sandbox', '/sandbox/my-sandboxing', '/sandbox/all-sandboxing']}
        render={(routeProps) => (
          <SandboxPage {...routeProps}>
            <SandboxPanel />
          </SandboxPage>
        )}
      />
      <Route path='/terms' show={page.isEnabled('terms')} component={TermsPage} />
      <Route path='/minimum-customer-terms' component={CustomerTermsPage} />
      <Route
        path='/accessibility'
        show={page.isEnabled('accessibility')}
        component={AccessibilityPage}
      />
      <Route
        path='/engine-agreement'
        show={page.isEnabled('engine-agreement')}
        component={EngineAgreementPage}
      />
      <Route exact path='/pricing' show={page.isEnabled('pricing')} component={PricingPage} />
      <Route exact path='/pricing/:plan' show={page.isEnabled('pricing')} component={PricingPage} />
      <ProtectedRoute path='/account' component={AccountPage} />
      <ProtectedRoute path='/u/:account/settings/team' component={TeamDetailPage} />
      <Route component={PageNotFound} />
    </Switch>
  );
};

const App = () => (
  <ScrollJitter>
    <Providers>
      <IconBase />
      <Routes />
      <Notification />
      <ModalConductor />
    </Providers>
  </ScrollJitter>
);
export default App;
