import React, { Fragment } from 'react'; // eslint-disable-line
import { defineMessages, useIntl } from 'react-intl';
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Loader from 'views/components/Loader';
import SEO from 'views/components/layout/SEO';
import BaseLayout from 'views/components/layout/BaseLayout';
import { FileUpload } from './FileUpload';
import styled from '@emotion/styled';
import Card from '@material-ui/core/Card';
import useIsPrivateContext from 'hooks/useIsPrivateContext';
import { useUser } from 'views/components/providers/UserProvider';
import useHasFeature from 'hooks/useHasFeature';

const Wrapper = styled.div`
  margin-top: ${styles.spacing.lg};
  margin-bottom: ${styles.spacing.lg};
`;

const StyledCard = styled(Card)`
  padding: ${styles.spacing.md} ${styles.spacing.xl};
  margin: ${styles.spacing.sm};
`;

const StyledLoader = styled(Loader)`
  margin: 0 auto;
  text-align: center;
  position: relative;
  top: calc(100vh / 4);
`;

const messages = defineMessages({
  title: {
    id: 'scanPage.title',
    defaultMessage: 'Scan',
  },
});

const ScanPage = () => {
  const intl = useIntl();
  const isPrivateContext = useIsPrivateContext();
  const { classes } = useStyles();
  const user = useUser();
  const { loading: featureLoading, hasFeature: hasScanFeature } = useHasFeature('scan');

  const hasLimitedAccess = user.context?.isLimitedAccess;

  return (
    <Fragment>
      <SEO title={intl.formatMessage(messages.title)} />
      <BaseLayout variant={isPrivateContext ? undefined : 'transparent'}>
        <Wrapper>
          {featureLoading ? (
            <StyledLoader />
          ) : hasLimitedAccess && !hasScanFeature ? (
            <div className='container'>
              <div className='col-12'>
                <StyledCard>
                  <div css={classes.container}>
                    <h2 className='h2 h-mb-xxs'>Limited Access Context</h2>
                    <div>
                      <p>You have been granted limited access to this account.</p>
                      <p>
                        Please use the navigation at the top to select the task you wish to perform.
                      </p>
                    </div>
                  </div>
                </StyledCard>
              </div>
            </div>
          ) : (
            <FileUpload showTerms={true} />
          )}
        </Wrapper>
      </BaseLayout>
    </Fragment>
  );
};

const useStyles = makeStyles({
  base: {
    container: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      gap: '7rem',
      flexGrow: 1,
    },
  },
  light: {
    container: {},
  },
  dark: {
    container: {},
  },
});
export default ScanPage;
