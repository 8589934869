import React from 'react'; // eslint-disable-line
import { jsx, ObjectInterpolation } from '@emotion/core'; /** @jsxRuntime classic */ /** @jsx jsx */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { truncateFloat } from 'utils/conversions/conversions';
import styles from 'views/styles';
import { PolyScoreBar } from './PolyScoreBar';
import StatusIcon from '../StatusIcon';

interface IPolyScore {
  className?: string;
  classNumber?: ObjectInterpolation<any>;
  bar?: boolean;
  polyscore: number | null;
  iconSize?: 'small' | 'normal' | 'tiny';
}

export enum EPolyScoreThreshold {
  'none' = 'none',
  'benign' = 0,
  'caution' = 0.2,
  'malicious' = 0.7,
}

export type PolyScoreResult = keyof typeof EPolyScoreThreshold;

export const getPolyScoreResult = (polyscore: number | null): PolyScoreResult => {
  if (polyscore === null) return 'none';
  const truncated = polyscore && truncateFloat(polyscore);
  let result: PolyScoreResult = 'benign';
  if (truncated >= EPolyScoreThreshold.caution) {
    result = truncated >= EPolyScoreThreshold.malicious ? 'malicious' : 'caution';
  }
  return result;
};

export const getPolyScoreColor = (polyscore: number | null) => {
  switch (getPolyScoreResult(polyscore)) {
    case 'benign':
      return styles.color.medGreen;
    case 'caution':
      return styles.color.yellow;
    case 'malicious':
      return styles.color.red;
    default:
      return styles.color.xLightGrey;
  }
};

export const getPolyScoreWidth = (polyscore: number | null) => {
  switch (getPolyScoreResult(polyscore)) {
    case 'benign':
      return '33.33%';
    case 'caution':
      return '66.67%';
    case 'malicious':
      return '100%';
    default:
      return '0%';
  }
};

export const PolyScore = ({
  className,
  classNumber,
  polyscore,
  bar = true,
  iconSize = 'normal',
}: IPolyScore) => {
  const { classes } = useStyles(polyscore);
  const truncated = polyscore && truncateFloat(polyscore).toFixed(2);
  const iconContainer = iconSize === 'small' ? classes.smallIconWrap : classes.iconWrap;
  return (
    <div className={className}>
      {polyscore === null ? (
        <div css={iconSize !== 'tiny' && iconContainer}>
          <StatusIcon
            sidebar={true}
            css={iconSize === 'small' || iconSize === 'tiny' ? classes.smallIcon : classes.icon}
            status='fail'
          />
        </div>
      ) : (
        <p css={[classes.num, classNumber]}>{truncated}</p>
      )}
      {bar ? <PolyScoreBar polyscore={polyscore} color={true} size='large' /> : null}
    </div>
  );
};

const useStyles = makeStyles((polyscore: number | null) => {
  const color = getPolyScoreColor(polyscore);
  return {
    base: {
      num: {
        color,
        fontFamily: styles.font.family.main,
        fontWeight: styles.font.weight.bold,
        fontSize: '4rem',
        textAlign: 'right',
        lineHeight: 1.2,
      },
      iconWrap: {
        textAlign: 'center',
        marginTop: '5rem !important',
        marginBottom: styles.spacing.tiny,
      },
      icon: {
        fontSize: 6,
      },
      smallIcon: {
        fontSize: 2,
        marginTop: styles.spacing.tiny,
      },
      smallIconWrap: {
        marginBottom: styles.spacing.tiny,
      },
    },
  };
});
