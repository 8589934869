import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { useLocation } from 'react-router-dom';
import { useAuth } from 'views/components/providers/AuthProvider';
import Icon from 'views/components/Icon';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Button from '@material-ui/core/Button';
import { Sandboxing } from 'models/Sandboxing';
import ContactSalesForFeature from 'views/components/ContactSalesForFeature/ContactSalesForFeature';
import useIsCommunityPlan from 'hooks/useIsCommunityPlan';
import { isPolyswarmTenant } from 'tenants';

type SandboxingOptions = 'allSandboxing' | 'mySandboxing' | 'detailsPage' | 'custom';
export type ForbiddenOptions = 'notAllowed' | 'notLoggedIn';
type EmptySandboxingProps = {
  title?: string;
  infoText?: string | React.ReactNode;
  type: SandboxingOptions;
  forbidden?: ForbiddenOptions;
};

// Used for not allowed and not logged in users
export const emptyRowData = [
  {
    sandboxOn: '2022-09-21 18:34:11',
    target: 'url.com',
    type: 'URL',
    sha256: '4564b2edc0696fb8c',
    sandboxProvider: 'sandboxProvider',
    status: 'Status',
    triggeredBy: 'User',
  },
] as Sandboxing[];

const messages = {
  title: 'Sandbox History',
  contactSales: 'Contact Sales',
  logIn: 'Log in / Sign up',
  detailsPage: 'No Sandbox results for this artifact.',
  mySandboxing: isPolyswarmTenant
    ? 'View all sandbox detonations for an artifact.'
    : 'This feature is not included in your subscription.',
  allSandboxing: isPolyswarmTenant
    ? 'View all sandbox detonations for an artifact.'
    : 'This feature is not included in your subscription.',
  notAllowed: isPolyswarmTenant
    ? 'Contact sales to add this sandboxing feature to your plan.'
    : 'Contact your sales representative if you want to upgrade',
  notAllowedFree: isPolyswarmTenant
    ? 'Upgrade your plan to access sandboxing.'
    : 'Contact your sales representative if you want to upgrade',
  notLoggedIn: 'Log in or Create an account to access sandboxing.',
  notAdminOrOwner: 'Contact your Team Owner or Team Admin to add additional features to your plan.',
  reTriggerSandbox:
    'Click the Re-Sandboxing button to trigger a sandbox detonation for this artifact.',
};

const EmptySandboxing = ({
  infoText,
  title,
  type,
  forbidden: _forbidden,
}: EmptySandboxingProps) => {
  const { classes } = useStyles();
  const location = useLocation();
  const { login } = useAuth();
  const isCommunityPlan = useIsCommunityPlan();
  const forbidden = _forbidden === 'notAllowed' && isCommunityPlan ? 'notAllowedFree' : _forbidden;

  return (
    <div css={classes.container}>
      <div style={{ position: 'relative' }}>
        <Icon css={classes.iconSandbox} name='sandbox' />
        {['allSandboxing', 'mySandboxing'].includes(type) && (
          <Icon css={classes.iconClock} name='clockface2' />
        )}
      </div>
      <div style={{ textAlign: 'center' }}>
        {type === 'custom' && (
          <Typography gutterBottom variant='h4'>
            {!!title ? title : messages.title}
          </Typography>
        )}
        {!!type && !!forbidden && type !== 'custom' && forbidden !== 'notLoggedIn' && (
          <Typography variant='subtitle1'>{messages[type]}</Typography>
        )}
        {type === 'custom' && !!infoText && <Typography variant='subtitle1'>{infoText}</Typography>}
        {type !== 'custom' && forbidden && (
          <Typography variant='subtitle1'>{messages[forbidden]}</Typography>
        )}
        {forbidden?.startsWith('notAllowed') &&
          isPolyswarmTenant &&
          (isCommunityPlan ? (
            <Button
              css={classes.button}
              variant='contained'
              color='primary'
              onClick={() => window.open('/pricing', '_blank')}
            >
              View Plans
            </Button>
          ) : (
            <ContactSalesForFeature size='large' feature='sandbox_search' />
          ))}
        {forbidden === 'notLoggedIn' && (
          <Button
            css={classes.button}
            variant='contained'
            color='primary'
            onClick={() => login(location.pathname)}
          >
            {messages.logIn}
          </Button>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles((isIconFilled = true) => ({
  base: {
    container: {
      width: '100%',
      height: '50vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    iconSandbox: {
      fontSize: '5rem',
    },
    iconClock: {
      fontSize: '2rem',
      position: 'absolute',
      bottom: 15,
      right: '-25px',
    },
    button: {
      marginTop: '2rem !important',
    },
  },
  light: {
    iconSandbox: {
      stroke: `${styles.color.purple} !important`,
    },
    iconClock: {
      stroke: styles.color.purple,
    },
  },
  dark: {
    iconSandbox: {
      stroke: `${styles.color.white} !important`,
    },
    iconClock: {
      stroke: styles.color.white,
    },
  },
}));
export default EmptySandboxing;
