import tenant from 'tenants';
import type { Pages } from 'tenants/config';
import { useUser } from 'views/components/providers/UserProvider';
import useHasFeature from './useHasFeature';

const featureTagsPagesMap = {
  scan: 'scan',
  'scan-results': ['scan', 'hash_search', 'metadata_search', 'download'],
  search: ['hash_search', 'metadata_search', 'ioc_user'],
  hunt: ['hunting_yara_rules', 'live_hunt', 'historical_hunt'],
  sandbox: ['sandbox_request', 'sandbox_search'],
  'manual-analysis': 'manual_analysis',
};

const defaultPagesForLimitedAccess = [
  'privacy',
  'terms',
  'minimum-customer-terms',
  'company',
  'docs',
  'status',
];

export function useIsPageEnabled() {
  const user = useUser();
  const { checkFeature, loading } = useHasFeature();

  const hasLimitedAccess = user.context?.isLimitedAccess;

  const isEnabled = (page: Pages) => {
    if (
      hasLimitedAccess &&
      page !== 'manual-analysis' &&
      !defaultPagesForLimitedAccess.includes(page)
    ) {
      return checkFeature(featureTagsPagesMap[page as keyof typeof featureTagsPagesMap]);
    }
    if (user.id === -1 && tenant.isLogingRequired) {
      return tenant.publicPages?.includes(page);
    }
    if (tenant.enabledPages === 'all') {
      if (tenant.disabledPages?.includes(page)) {
        return false;
      }
      return true;
    }
    return tenant.enabledPages.includes(page);
  };

  const shouldBeOnNavbar = (page: Pages) => {
    if (hasLimitedAccess && !defaultPagesForLimitedAccess.includes(page)) {
      return checkFeature(featureTagsPagesMap[page as keyof typeof featureTagsPagesMap]);
    }
    if (tenant.navbarPages) {
      return tenant.navbarPages.includes(page);
    }
    return true;
  };

  return {
    loading: hasLimitedAccess ? loading : false,
    isEnabled,
    shouldBeOnNavbar,
  };
}
