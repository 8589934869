import React, { useState } from 'react';
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { uniqBy } from 'lodash';
import styled from '@emotion/styled';
import { TableCell, Tooltip } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Popover from 'views/components/Popover';
import { useUser } from 'views/components/providers/UserProvider';
import { ApiKeyData } from 'models/ApiKey';
import styles from 'views/styles';
import Icon from 'views/components/Icon';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { Row } from 'views/components/table/CustomTable';
import CustomTable from 'views/components/table/CustomTable';
import CustomTableHead from 'views/components/table/CustomTableHead';
import ApiKeyTableRow from './ApiKeyTableRow';

interface IApiKeyTableProps {
  results: ApiKeyData[];
  archiveApiKey: (id: string, key: string) => void;
  team: number;
  externalKeys: boolean;
}

const messages = {
  expire: 'Expiration date',
  key: 'API Key',
  team: 'Team Name',
  community: 'Community',
  name: 'Name',
};

const Title = styled.h3`
  font-size: 2rem;
  font-weight: bold;
  margin-left: 3rem;
  margin-bottom: 2rem;
`;

const ROWS = (hasCommunity: boolean, showName: boolean = true) =>
  [
    showName && {
      id: 'name',
      numeric: false,
      label: messages.name,
      sortable: false,
    },
    {
      id: 'team',
      numeric: false,
      label: messages.team,
      sortable: false,
    },
    hasCommunity && {
      id: 'community',
      numeric: false,
      label: messages.community,
      sortable: false,
    },
    {
      id: 'key',
      numeric: false,
      label: messages.key,
      sortable: false,
    },
    {
      id: 'expire',
      numeric: false,
      label: messages.expire,
      sortable: false,
    },
  ].filter(Boolean) as Row<ApiKeyData>[];

const ApiKeyTable = ({ results, team, archiveApiKey, externalKeys }: IApiKeyTableProps) => {
  const { classes } = useStyles();
  const [filteredTeams, setFilteredTeams] = useState<string[]>([]);
  const user = useUser();
  const accountNumber = user.context?.accountNumber || user.accountNumber;

  const teamNames = uniqBy(
    results
      .map((key) => (key.team ? { label: key.team.name, value: key.team.name } : null))
      .filter((key) => key !== null),
    (row) => row?.value
  ) as { label: string; value: string }[];

  const teamsExternal = user?.teamsExternal ?? [];
  const teams = user?.teams ?? [];
  const hasPrivateCommunity =
    teams.some((team) => team.hasPrivateCommunity) ||
    teamsExternal.some((team) => team.hasPrivateCommunity);
  const title = team ? (externalKeys ? 'Limited Access' : 'Team Account') : 'Personal Account';

  return (
    <div style={{ margin: '3rem 0', width: '100%' }}>
      <Title>
        <div style={{ display: 'flex' }}>
          {title} API Keys{' '}
          {!team && (
            <Tooltip
              title={
                <div>
                  <div>{user.email}</div>
                  <div>{accountNumber}</div>
                </div>
              }
              placement='top'
            >
              <div>
                <Icon css={classes.info} name='info' />
              </div>
            </Tooltip>
          )}
        </div>
      </Title>
      <CustomTable<ApiKeyData>
        rows={results.filter((apiKey) => {
          if (filteredTeams.length === 0) {
            return true;
          }
          return filteredTeams.includes(apiKey.team?.name || '');
        })}
        renderTableHead={() => (
          <CustomTableHead
            rows={ROWS(!!team && hasPrivateCommunity, !externalKeys)}
            showActions={true}
            mustRenderHeaderCell={(row) => !!team || row.id !== 'team'}
            renderHeaderCell={(row) => {
              if (!team && row.id === 'team') return <></>;

              return (
                <TableCell key={row.id} align={row.numeric ? 'right' : 'left'}>
                  <div css={classes.cellContainer}>
                    {row.label}
                    {row.id === 'team' && teamNames.length > 0 && (
                      <Popover
                        placement='bottom-start'
                        button={
                          <Tooltip title='Filter by team' placement='top'>
                            <div css={classes.iconContainer}>
                              <Icon
                                css={[classes.icon, filteredTeams.length && classes.iconActive]}
                                name='dropdown'
                              />
                            </div>
                          </Tooltip>
                        }
                        content={
                          <div css={classes.popover}>
                            <div
                              style={{ height: `${teamNames.length * 4.75}rem` }}
                              css={classes.listColumns}
                            >
                              {teamNames.map((item) => (
                                <div css={classes.pane} key={item.label}>
                                  <label>
                                    <Checkbox
                                      color='primary'
                                      checked={filteredTeams.includes(item.value)}
                                      onChange={(_e) => {
                                        filteredTeams.includes(item.value)
                                          ? setFilteredTeams(
                                              filteredTeams.filter((role) => role !== item.value)
                                            )
                                          : setFilteredTeams([...filteredTeams, item.value]);
                                      }}
                                    />
                                    {item.label}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        }
                      />
                    )}
                  </div>
                </TableCell>
              );
            }}
          />
        )}
        renderTableRow={(row: ApiKeyData) => {
          return (
            <ApiKeyTableRow
              hasPrivateCommunity={hasPrivateCommunity}
              teams={teams}
              key={row.key}
              rowData={row}
              team={team}
              archiveApiKey={archiveApiKey}
              showName={!externalKeys}
            />
          );
        }}
      />
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    iconContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
    cellContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
    },
    icon: {
      fontSize: '0.6rem',
    },
    popover: {
      padding: '2rem 5rem 2rem 2rem',
      borderRadius: '0.5rem',
      marginTop: '1rem',
      fontWeight: 600,
      border: `1px solid ${styles.color.lightGrey}`,
    },
    listColumns: {
      marginTop: '1rem',
    },
    pane: {
      '&:hover': {
        cursor: 'grab',
      },
    },
    info: {
      cursor: 'help',
      fontSize: '0.5rem',
      marginLeft: styles.spacing.tiny,
      color: styles.color.lightGrey,
    },
  },
  light: {
    icon: {
      color: styles.color.black,
    },
    iconActive: {
      color: styles.color.purple,
    },
    popover: {
      backgroundColor: styles.color.ulGrey,
    },
  },
  dark: {
    icon: {
      color: styles.color.greyishPurple,
    },
    iconActive: {
      color: styles.color.lightBlue,
    },
    popover: {
      backgroundColor: styles.color.darkOffBlack,
    },
  },
});
export default ApiKeyTable;
