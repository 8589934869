import React, { useState, ReactNode } from 'react';
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import Icon from 'views/components/Icon';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useSticky from 'hooks/useSticky';
import { RootState } from 'state/root';
import { NormalizedArtifactMetadata } from 'models/Submission';
import { HuntingActionName } from 'state/hunting/types';
import { loadingSelector } from 'state/requests/selectors';

interface Props {
  renderSidebar: ReactNode;
  renderCollapsedSidebar: ReactNode;
  renderPanel: ReactNode;
  sidebarSticky?: boolean;
}

interface IMetadataSearchTabState {
  metadataSearchResults: NormalizedArtifactMetadata[];
}

const PageSidebarTransitionLayout = ({
  renderSidebar,
  renderCollapsedSidebar,
  renderPanel,
  sidebarSticky = false,
}: Props) => {
  const [isFade, toggleFade] = useState(true);
  const { classes } = useStyles(isFade);
  const location = useLocation();
  const [ref, nextElementRef] = useSticky<HTMLDivElement>({
    topOffset: 10,
    scrollMargin: 100,
    stickyActive: sidebarSticky,
  });
  const { hashSearchResults } = useSelector((state: RootState) => state.submission);

  const { metadataSearchResults } = useSelector<RootState, IMetadataSearchTabState>(
    ({ hunting: { metadataSearchResults }, requests }) => ({
      isLoading: loadingSelector(requests, [HuntingActionName.GET_METADATA_SEARCH_RESULTS]),
      metadataSearchResults,
    })
  );

  let sidebarActive;
  if (location.pathname === '/search/hash') {
    sidebarActive = Object.keys(hashSearchResults).length === 0;
  } else {
    sidebarActive = metadataSearchResults.length === 0;
  }

  sidebarActive = sidebarActive && renderSidebar;

  return (
    <div css={classes.root} className='container h-mt-sm h-mb-lg'>
      <div style={{ position: 'absolute', zIndex: 100 }}>
        <div css={classes.sidebarButton}>
          {isFade && (
            <Icon
              css={classes.svgIcon}
              key='fullDetail'
              onClick={() => toggleFade(!isFade)}
              name='chevron-up'
            />
          )}
          {!isFade && (
            <Icon
              css={classes.svgIcon}
              key='shortDetail'
              onClick={() => toggleFade(!isFade)}
              name='chevron-up'
            />
          )}
        </div>
      </div>
      {!isFade && <div style={{ position: 'absolute', width: 80 }}>{renderCollapsedSidebar}</div>}
      <div css={classes.parentContainer}>
        {sidebarActive && (
          <div
            ref={ref}
            css={[
              classes.sidebarTransition,
              isFade ? classes.sidebarExpanded : classes.sidebarCollapsed,
            ]}
          >
            {typeof renderSidebar === 'function' ? renderSidebar() : renderSidebar}
          </div>
        )}
        <div
          ref={nextElementRef}
          css={[classes.divTransition, isFade ? classes.mainExpanded : classes.mainCollapsed]}
        >
          {typeof renderPanel === 'function' ? renderPanel() : renderPanel}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((isExpanded) => ({
  base: {
    root: {
      '@media (min-width: 1901px)': {
        maxWidth: '80% !important',
      },
      '@media (min-width: 1441px)': {
        maxWidth: '90% !important',
      },
    },
    parentContainer: {
      display: 'flex',
      gap: isExpanded ? '32px' : '100px',
    },
    // Sidebar styling
    sidebarTransition: {
      transition: 'width 0.6s ease, opacity 0.6s ease',
    },
    sidebarExpanded: {
      opacity: 1,
      visibility: 'visible',
    },
    sidebarCollapsed: {
      width: '0',
      height: 0,
      opacity: 0,
      visibility: 'hidden',
    },
    // Main content styling
    divTransition: {
      transition: 'width 0.6s ease',
    },
    mainExpanded: {
      width: 'calc(100% - 300px)',
    },
    mainCollapsed: {
      width: '100%',
    },
    sidebarButton: {
      position: 'relative',
      left: !isExpanded ? '-16px' : '-10px',
      top: !isExpanded ? '14px' : '12px',
      transform: !isExpanded ? 'rotate(-90deg)' : 'rotate(90deg)',
    },
    svgIcon: {
      width: '1.5em !important',
      height: '1.5em !important',
      backgroundColor: '#DBCCF8',
      borderRadius: 32,
      fill: '#6D3AEC !important',
      '& > use': {
        transform: 'translate(-4px, -2px)',
      },
    },
  },
}));
export default PageSidebarTransitionLayout;
