import React from 'react';
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { makeStyles, useTheme } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Icon from 'views/components/Icon';
import { SandboxInstanceStatus } from 'models/Sandbox';

export type Status = 'Success' | 'Pending' | 'Failed' | SandboxInstanceStatus;

const statusToColor = (status: Status, theme: 'light' | 'dark') => {
  switch (status) {
    case 'Success':
      return theme === 'light' ? styles.color.medGreen : styles.color.green;
    case 'Pending':
      return theme === 'light' ? styles.color.black : styles.color.white;
    case 'Failed':
      return styles.color.lightRed;
    default:
      return theme === 'light' ? styles.color.black : styles.color.white;
  }
};

const StatusIcon = ({ status }: { status: Status }) => {
  const { classes } = useStyles();
  switch (status) {
    case 'Success':
    case SandboxInstanceStatus.SUCCEEDED:
      return (
        <div css={classes.iconContainer}>
          <Icon css={classes.icon} name='check' />
        </div>
      );
    case 'Pending':
    case SandboxInstanceStatus.PENDING:
    case SandboxInstanceStatus.STARTED:
      return <Icon css={classes.iconClock} name='clockface' />;
    case 'Failed':
    case SandboxInstanceStatus.FAILED:
    case SandboxInstanceStatus.FAILED_REIMBURSED:
    case SandboxInstanceStatus.TIMEDOUT:
      return <Icon css={classes.iconWarning} name='warning' />;
    default:
      return null;
  }
};

const StatusIconContainer = ({
  status,
  withoutText = false,
}: {
  status: Status;
  withoutText?: boolean;
}) => {
  const { classes } = useStyles();
  const theme = useTheme();
  return (
    <div css={classes.cellContainer}>
      {!withoutText && (
        <span css={{ color: statusToColor(status, theme) }}>
          {status.toLowerCase().split('_').join(' ')}
        </span>
      )}
      <StatusIcon status={status} />
    </div>
  );
};

export default StatusIconContainer;

const useStyles = makeStyles({
  base: {
    cellContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
      '> span': {
        textTransform: 'capitalize',
      },
    },
    iconContainer: {
      height: '2.2rem',
      width: '2.2rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '100%',
      padding: '0.6rem',
    },
    icon: {
      fontSize: '0.4rem',
    },
    iconWarning: {
      color: styles.color.lightRed,
      fontSize: '0.6rem',
    },
    iconClock: {
      fontSize: '0.7rem',
    },
  },
  light: {
    green: {
      color: styles.color.medGreen,
    },
    iconContainer: {
      backgroundColor: styles.color.medGreen,
      border: `2px solid ${styles.color.purple}`,
    },
    icon: {
      color: styles.color.purple,
    },
    iconClock: {
      color: styles.color.purple,
    },
  },
  dark: {
    green: {
      color: styles.color.green,
    },
    iconContainer: {
      backgroundColor: styles.color.green,
      border: `2px solid ${styles.color.lightBlue}`,
    },
    icon: {
      color: styles.color.lightBlue,
    },
    iconClock: {
      color: styles.color.lightBlue,
    },
  },
});
