// @ts-nocheck
import React, { useEffect, useContext } from 'react';
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import qs from 'query-string';
import config from 'config';
import tenant from 'tenants';
import isEmpty from 'lodash/isEmpty';
import inRange from 'lodash/inRange';
import styled from '@emotion/styled';
import styles from 'views/styles';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { SandboxActionName } from 'state/sandbox/types';
import { RootState } from 'state/root';
import { errorSelector } from 'state/requests/selectors';
import Card from 'views/components/layout/Card';
import SidebarDetail from 'views/components/layout/Sidebar/SidebarDetail';
import SidebarLoading from 'views/components/layout/Sidebar/SidebarLoading';
import { toISODateTime } from 'utils/date/date';
import { SandboxProviders } from 'models/Sandbox';
import { useAuth } from 'views/components/providers/AuthProvider';
import useHasFeature from 'hooks/useHasFeature';
import Icon from 'views/components/Icon';
import { useUser } from 'views/components/providers/UserProvider';
import SidebarActions from 'views/components/layout/Sidebar/SidebarActions';
import { usePivoting } from 'views/components/providers/PivotingProvider';
import { closeModal, openModal } from 'state/modal/actions';
import { showNotification } from 'state/notification/actions';
import joinUrl from 'utils/joinUrl';
import { Sandbox, SandboxReport } from 'models/Sandbox';
import { isPivotingEnabled } from 'tenants/utils';
import useIsCommunityPlan from 'hooks/useIsCommunityPlan';
import { getSandboxAvailability, SandboxSelectionContext } from './SandboxDetails';

const messages = {
  resandboxingSuccess:
    'The artifact is queued for sandboxing. You can monitor the status on the Sandbox → My Sandbox tab.',
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const DetailsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const VerticalBar = styled.hr`
  margin: 24px 0px;
`;

const NoResultTitle = styled.h2`
  font-weight: 700;
  font-size: 2.2rem;
  text-align: center;
  color: ${styles.color.red};
`;

const ScoreDescription = styled.span`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const NoResultsCard = ({
  isLimitedRestriction = false,
  provider,
  vm,
  sha256,
  unselected = false,
}) => {
  const { classes } = useStyles();
  const location = useLocation<{ sandboxId?: string } | undefined>();

  const { sandboxId = '' } = qs.parse(location.search);

  const hasInfo = !!provider;

  return (
    <Card css={[classes.root, (unselected || !isEmpty(sandboxId)) && classes.unselectedSandbox]}>
      {hasInfo && <h1 css={classes.title}>{!!vm ? `${provider}: ${vm}` : provider}</h1>}
      <NoResultTitle>
        {isLimitedRestriction ? 'Access Restricted' : 'No sandbox results found.'}
      </NoResultTitle>
      {!isEmpty(sha256) && <VerticalBar />}
      {!hasInfo && (
        <DetailsContainer>
          <SidebarDetail label='SHA-256' value={sha256} copy={true} monospace={true} />
        </DetailsContainer>
      )}
    </Card>
  );
};

const UnAuthSidebarCard = () => {
  const { classes } = useStyles();

  return (
    <Container>
      <Card css={[classes.root]}>
        <h1 css={{ ...classes.title, textTransform: 'none' }}>
          Log in or create an account to access sandbox data.
        </h1>
        <div css={classes.subDetail}>
          <div style={{ filter: 'blur(4px)' }}>{toISODateTime(new Date())}</div>
        </div>
        <VerticalBar />
        <DetailsContainer>
          <SidebarDetail blurred label='Sandbox Id' value='33969787630527805' monospace={true} />
          <SidebarDetail
            blurred
            label='SHA-256'
            value='f0c542cc2a57d6f3de08e05e2b35b87f5e68f4e011821633185ce632a6710cda'
            monospace={true}
          />
          <SidebarDetail blurred label='Family' value='Unknown' />
        </DetailsContainer>
      </Card>
      <SandboxSidebarActions disabledHistory disabledSandbox disabledPivot disabledShare />
    </Container>
  );
};

const SandboxSidebarActions = ({
  selectedSandbox = {},
  disabledPivot = false,
  disabledShare = false,
  disabledSandbox = false,
  disabledHistory = false,
  disabledReport = false,
}: {
  selectedSandbox: Sandbox<SandboxReport>;
  disabledPivot: boolean;
  disabledShare: boolean;
  disabledSandbox?: boolean;
  disabledHistory?: boolean;
  disabledReport?: boolean;
}) => {
  const history = useHistory();
  const isSandboxDisabledInTenant = tenant.disabledPages?.includes('sandbox');
  const dispatch = useDispatch();
  const {
    active: isPivotActive,
    onToggleActive: onTogglePivotActive,
    setArtifactId,
  } = usePivoting();
  const match = useRouteMatch<{ artifactType: string; sha256: string }>();
  const { isAuthenticated } = useAuth();
  const { checkFeature, hasPermission: hasSandboxRequestFeature } =
    useHasFeature('sandbox_request');
  const isCommunityPlan = useIsCommunityPlan();

  const noSandboxFeature =
    !isAuthenticated || !hasSandboxRequestFeature || !checkFeature('sandbox_search');
  const noReportFeature = !isAuthenticated || isCommunityPlan;

  const resandboxing = () => {
    if (isSandboxDisabledInTenant) {
      return null;
    } else {
      if (noSandboxFeature) {
        return history.push('/pricing/enterprise');
      }

      dispatch(
        openModal('SUBMIT_TO_SANDBOX_MODAL', {
          onSubmit: () => {
            dispatch(
              showNotification({
                status: 'success',
                message: messages.resandboxingSuccess,
              })
            );
            dispatch(closeModal());
          },
          instanceId: selectedSandbox.instanceId,
          defaultProvider: selectedSandbox.config?.vm?.name
            ? selectedSandbox.sandbox + ' - ' + selectedSandbox.config.vm.name
            : selectedSandbox.sandbox,
          defaultType: match?.params.artifactType === 'url' ? 'url' : 'hash',
          defaultHash: match?.params?.sha256,
          isResandbox: true,
        })
      );
    }
  };

  useEffect(() => {
    if (match.params.sha256) {
      setArtifactId(match.params.sha256);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.sha256]);

  return (
    <SidebarActions
      className='h-mt-grid'
      items={[
        {
          icon: 'sandbox',
          name: 'Re-Sandbox',
          onClick: resandboxing,
          dataCy: 'sandboxingBtn',
          disabled: !!disabledSandbox || noSandboxFeature,
          warning: noSandboxFeature,
          tooltipTitle:
            !Boolean(disabledSandbox) && (noSandboxFeature || isSandboxDisabledInTenant) ? (
              <>
                Sandboxing is <span style={{ fontWeight: 'bold' }}>unavailable</span> for current
                plan.
              </>
            ) : (
              ''
            ),
        },
        {
          icon: 'share',
          name: 'Share',
          shareUrl: `${config.url}${match.url}`,
          dataCy: 'shareSandboxBtn',
          disabled: disabledShare,
        },
        {
          icon: 'pivot',
          name: 'Pivot',
          dataCy: 'sandboxPivotBtn',
          onClick: () => {
            onTogglePivotActive(match.params.sha256);
            setTimeout(() => {
              if (!isPivotActive) {
                document.getElementById('pivot-search')?.scrollIntoView({
                  behavior: 'smooth',
                });
              }
            }, 100);
          },
          iconFilled: isPivotActive,
          isShown: isPivotingEnabled(),
          disabled: disabledPivot,
        },
        {
          icon: 'history',
          name: 'History',
          disabled: !!disabledHistory || noSandboxFeature,
          tooltipTitle: noSandboxFeature
            ? 'Your subscription does not include this feature. Please contact your sales representative to add it.'
            : '',
          onClick: () => {
            window.open(
              joinUrl(
                config.url,
                `sandbox/all-sandboxing?sha256=${match.params.sha256}&start_option=DAYS&start_value=90`
              ),
              '_blank'
            );
          },
          dataCy: 'sandboxHistoryBtn',
        },
        {
          icon: 'generate-report',
          name: 'Generate Report',
          onClick: () => {
            dispatch(
              openModal('GENERATE_REPORT', {
                type: 'sandbox',
                id: selectedSandbox.id,
              })
            );
          },
          disabled: disabledReport || noReportFeature,
          dataCy: 'scanDownloadPdfBtn',
        },
      ]}
    />
  );
};

const TooltipContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;

  & > b {
    font-weight: bolder;
  }
  & > ul {
    list-style: disc;
    list-style-position: inside;
  }
`;

const StarIcon = () => (
  <Icon style={{ color: styles.color.purple, fontSize: '0.8rem' }} className='icon' name='star' />
);

const SCORE_MAP = {
  [SandboxProviders.CAPE]: [
    [
      '0-3',
      'Benign',
      'If the file is benign, likely all trusted files are digitally signed.',
      styles.color.black,
      styles.color.white,
    ],
    [
      '4-6',
      'Suspicious',
      'If the file is Suspicious-Unknown and triggered some signatures that has specific suspicious categories such as: ["network", "encryption", "anti-vm", "anti-analysis", "anti-av", "anti-debug", "anti-emulation", "persistence", "stealth", "discovery", "injection", "generic", "account", "bot", "browser", "allocation", "command"].',
      styles.color.darkYellow,
      styles.color.yellow,
    ],
    [
      '7-9',
      'Malicious',
      'If the file is Malicious-Unknown and triggered some signatures that have specific malicious categories, such as: ["malware", "ransomware", "infostealer", "rat", "trojan", "rootkit", "bootkit", "wiper", "banker", "bypass", "anti-sandbox", "keylogger"].',
      styles.color.darkRed,
      styles.color.lightRed,
    ],
    [
      '10',
      'Malicious',
      'The file is Malicious-Known (The sample is detected by YARA).',
      styles.color.darkRed,
      styles.color.lightRed,
    ],
  ],
  [SandboxProviders.TRIAGE]: [
    ['N/A', 'Not Available', '', styles.color.black, styles.color.white],
    [
      '1',
      'No (potentially) malicious behavior was detected.',
      `The report is incomplete or something went wrong, this could also occur in static reports.`,
      styles.color.black,
      styles.color.white,
    ],
    [
      '2-5',
      'Likely benign',
      `One or more interesting behaviors were detected. The detected actions are interesting enough to be notified about, but are not directly malicious.`,
      styles.color.black,
      styles.color.white,
    ],
    [
      '6-7',
      'Shows suspicious behavior',
      <TooltipContainer>
        <p>
          One or more suspicious actions were detected. The detected actions can be malicious, but
          also have (common) benign uses.
        </p>
        <b>Examples:</b>
        <ul>
          <li>Changing file permissions.</li>
          <li>Anti-VM behavior/trying to detect a VM.</li>
        </ul>
      </TooltipContainer>,
      styles.color.darkYellow,
      styles.color.yellow,
    ],
    [
      '8-9',
      'Likely malicious',
      <TooltipContainer>
        <p>One or more known damaging malware attack patterns were detected.</p>
        <b>Examples:</b>
        <ul>
          <li>The deleting of shadow copies on Windows.</li>
        </ul>
      </TooltipContainer>,
      styles.color.darkRed,
      styles.color.lightRed,
    ],
    [
      '10',
      'Known bad',
      <TooltipContainer>
        <b>Examples:</b>{' '}
        <ul>
          <li>A malware family was detected.</li>
        </ul>
      </TooltipContainer>,
      styles.color.darkRed,
      styles.color.lightRed,
    ],
  ],
};

const getEffectiveURL = (report: Sandbox<SandboxReport>['report'] | undefined, target: string) => {
  if (!report) {
    return null;
  }

  const requests = (report.requests || [])
    .map((request) => (request || {}).http_response || [])
    .filter((request) => request.length > 0)
    .flat();

  const getByAllowOrigin = () => {
    const [data] = requests
      .filter((request) => request.status === '200' || request.status === '201')
      .filter((request) =>
        request.headers.some(
          (header) => header.startsWith('access-control-allow-origin') && !header.includes(': *')
        )
      );
    return data
      ? data.headers
          .filter((header) => header.startsWith('access-control-allow-origin'))[0]
          .split(': ')[1]
      : null;
  };

  const removeUrlProtocol = (url) => url.replace(/(^\w+:|^)\/\//, '').replace('www.', '');

  const getByLocation = () => {
    const [data] = requests
      .filter((request) => request.status === '301' || request.status === '302')
      .filter((request) =>
        request.headers.some(
          (header) => header.startsWith('location') && !header.endsWith(removeUrlProtocol(target))
        )
      );
    const location = data
      ? data.headers.filter((header) => header.startsWith('location'))[0].split(': ')[1]
      : null;

    if (location && !location.startsWith('http')) {
      return target.endsWith('/') ? target.slice(0, -1) + location : target + location;
    }

    return location;
  };

  return getByLocation(report) ?? getByAllowOrigin(report);
};

const SandboxSidebar = () => {
  const user = useUser();
  const { theme, classes } = useStyles();
  const { requests } = useSelector((state: RootState) => state);
  const match = useRouteMatch<{ artifactType: string }>();
  const error = errorSelector(requests, [
    SandboxActionName.GET_SANDBOX_TASK_ID,
    SandboxActionName.GET_SANDBOXES_HASH,
  ]);
  const { topSandbox, selectSandboxId, selectedSandbox, sortedSandboxList } =
    useContext(SandboxSelectionContext);

  const sandboxType = match.params?.artifactType ?? '';
  const location = useLocation<{ sandboxId?: string } | undefined>();
  const { sandboxId = '' } = qs.parse(location.search);

  const { isAuthenticated } = useAuth();
  const { hasFeature: hasSandboxSearch } = useHasFeature('sandbox_search');

  const latestSandboxes = Object.values(sortedSandboxList)
    .reduce((acc, list) => {
      return acc.concat(list?.[0] ?? []);
    }, [])
    .sort((a, b) => {
      if (a.id === sandboxId) {
        return -1;
      }
      if (b.id === sandboxId) {
        return 1;
      }
      return 0;
    });
  const isLatestSandboxTop = latestSandboxes?.[0]?.id === sandboxId;

  const sandboxList = isLatestSandboxTop
    ? latestSandboxes
    : [].concat(!isEmpty(topSandbox) && !!sandboxId ? topSandbox : [], latestSandboxes);

  if (!isAuthenticated) {
    return <UnAuthSidebarCard />;
  }

  if (!Boolean(error) && !Boolean(sandboxList.length)) {
    return <SidebarLoading />;
  }

  const disabledSandbox = !getSandboxAvailability(selectedSandbox.status);

  const [sandboxTarget = {}] = selectedSandbox?.report?.targets ?? [];
  const sandboxBehavioralScore = sandboxTarget?.score ?? '0';
  const sandboxStaticScore = selectedSandbox?.report?.static?.score ?? '0';
  const sandboxTypes =
    selectedSandbox?.report?.analysis?.tags?.filter((tag) => !tag.includes('family')) ?? [];
  const sandboxTargetName =
    selectedSandbox?.artifact?.filename ??
    selectedSandbox?.config?.target ??
    sandboxTarget?.target ??
    'Unknown';

  const effectiveURL = getEffectiveURL(selectedSandbox?.report, sandboxTargetName);

  return (
    <Container>
      {sandboxList.filter(Boolean).map((item, index) => {
        const hasResults = getSandboxAvailability(item.status);
        const isItemSelected = item.id === selectedSandbox.id;

        const malwareList = item?.report?.malware_family ?? [];

        const provider = item?.config?.provider?.name ?? item?.sandbox;

        const score = (() => {
          switch (SandboxProviders[provider.toUpperCase()]) {
            case SandboxProviders.CAPE: {
              return `${Math.round(item?.report?.malscore ?? 0)}`;
            }
            case SandboxProviders.TRIAGE: {
              return item?.report?.analysis?.score ?? 'N/A';
            }
            default: {
              return '0';
            }
          }
        })();

        const selectedProvider = SandboxProviders[provider.toUpperCase() ?? ''];
        const scoreInfo = SCORE_MAP[selectedProvider].find(([scoreRange, info]) => {
          if (score === 'N/A' && scoreRange.includes('N/A')) {
            return true;
          }
          if (score !== 'N/A' && !scoreRange.includes('N/A')) {
            const [minN = '', maxN = ''] = scoreRange.split('-');
            const isInRange = inRange(
              Number(score),
              0,
              !!maxN ? Number(maxN) + 1 : Number(minN) + 1
            );
            return isInRange;
          }

          return false;
        });

        const isRestricted = user?.context?.isLimitedAccess && !hasSandboxSearch;

        return (
          <React.Fragment key={item.id}>
            {!hasResults || isRestricted ? (
              <NoResultsCard
                isLimitedRestriction={isRestricted}
                unselected={true}
                provider={isRestricted ? undefined : provider}
                vm={isRestricted ? undefined : item.config?.vm?.name}
                sha256={isRestricted ? item.sha256 : undefined}
              />
            ) : (
              <Card
                onClick={() => {
                  selectSandboxId(item.id);
                }}
                css={[classes.root, !isItemSelected && classes.unselectedSandbox]}
              >
                <div>
                  <div style={{ display: 'flex' }}>
                    {index === 0 && !isLatestSandboxTop && !isEmpty(sandboxId) ? (
                      <></>
                    ) : (
                      <div style={{ marginRight: 18 }}>
                        <StarIcon />
                      </div>
                    )}
                    <h1
                      {...(!isItemSelected ? { style: { fontSize: '1.75rem' } } : {})}
                      css={classes.title}
                    >
                      {!!item?.config?.vm?.name
                        ? `${item.sandbox} : ${item.config.vm.name}`
                        : item.sandbox}
                    </h1>
                  </div>
                  <div
                    {...(!isItemSelected ? { style: { marginBottom: 16 } } : {})}
                    css={classes.subDetail}
                  >
                    <div>{toISODateTime(new Date(item.created))}</div>
                    <>
                      <SidebarDetail
                        center
                        label={isItemSelected ? 'Summary Score' : undefined}
                        value={
                          <span
                            style={{
                              fontWeight: 700,
                              color: theme === 'dark' ? scoreInfo[4] : scoreInfo[3],
                            }}
                            j
                          >
                            {score === 'N/A' ? score : `${score} / 10`}
                          </span>
                        }
                      />
                      {isItemSelected && (
                        <SidebarDetail
                          center
                          label={undefined}
                          value={
                            <Tooltip title={scoreInfo[2]} placement='top'>
                              <ScoreDescription>
                                <span css={classes.summaryDesc}>{scoreInfo[1]}</span>
                                <Icon
                                  style={{ fontSize: '0.5rem' }}
                                  name='info'
                                  title='Score Info'
                                />
                              </ScoreDescription>
                            </Tooltip>
                          }
                        />
                      )}
                    </>
                  </div>
                </div>
                {isItemSelected && <VerticalBar />}
                <DetailsContainer>
                  {sandboxType === 'file' && (
                    <SidebarDetail
                      center
                      label={isItemSelected ? 'Malware Family' : undefined}
                      value={
                        isItemSelected
                          ? malwareList.length > 0
                            ? malwareList
                            : 'Unknown'
                          : malwareList?.[0] ?? 'Unknown'
                      }
                    />
                  )}
                  {isItemSelected && selectedSandbox.sandbox === SandboxProviders.TRIAGE && (
                    <SidebarDetail
                      center
                      label={
                        <Tooltip
                          title={`This score represents the likelihood of the Artifact having been used in a malicious manner previously.`}
                          placement='top'
                        >
                          <ScoreDescription>
                            <span>Static Analysis Score</span>
                            <Icon
                              style={{ fontSize: '0.5rem' }}
                              name='info'
                              title='Static Summary'
                            />
                          </ScoreDescription>
                        </Tooltip>
                      }
                      value={`${sandboxStaticScore} / 10`}
                    />
                  )}
                  {isItemSelected && selectedSandbox.sandbox === SandboxProviders.TRIAGE && (
                    <SidebarDetail
                      center
                      label={
                        <Tooltip
                          title={`This score represents the browser, host and network behaviour during a sandboxing session.`}
                          placement='top'
                        >
                          <ScoreDescription>
                            <span>Behavioral Score</span>
                            <Icon
                              style={{ fontSize: '0.5rem' }}
                              name='info'
                              title='Behavioral Summary'
                            />
                          </ScoreDescription>
                        </Tooltip>
                      }
                      value={`${sandboxBehavioralScore} / 10`}
                    />
                  )}
                  {isItemSelected && (
                    <Tooltip title={sandboxTargetName} placement='top'>
                      <div>
                        <SidebarDetail
                          copy
                          center
                          label={
                            sandboxType === 'file' ? 'Filename' : effectiveURL ? 'Target' : 'URL'
                          }
                          value={sandboxTargetName}
                        />
                      </div>
                    </Tooltip>
                  )}
                  {isItemSelected && effectiveURL && (
                    <SidebarDetail
                      copy
                      copyText={effectiveURL}
                      info={effectiveURL.length > 50 ? effectiveURL : undefined}
                      center
                      label={
                        <Tooltip
                          title='The URL of the primary webpage after redirection'
                          placement='top'
                        >
                          <ScoreDescription>
                            <span>Effective URL</span>
                            <Icon
                              style={{ fontSize: '0.5rem' }}
                              name='info'
                              title='The URL of the primary webpage after redirection'
                            />
                          </ScoreDescription>
                        </Tooltip>
                      }
                      value={
                        effectiveURL.length > 50 ? effectiveURL.slice(0, 50) + '...' : effectiveURL
                      }
                    />
                  )}
                  {isItemSelected && provider === SandboxProviders.TRIAGE && (
                    <SidebarDetail
                      center
                      label={'Type'}
                      value={sandboxTypes.length > 0 ? sandboxTypes : 'Unknown'}
                    />
                  )}
                </DetailsContainer>
                {isItemSelected && (
                  <>
                    <VerticalBar />
                    <DetailsContainer>
                      <SidebarDetail
                        label='Sandbox Id'
                        value={item.id}
                        copy={true}
                        monospace={true}
                      />
                      <SidebarDetail
                        label='SHA-256'
                        value={item.sha256}
                        copy={true}
                        monospace={true}
                      />
                    </DetailsContainer>
                  </>
                )}
              </Card>
            )}
          </React.Fragment>
        );
      })}
      <SandboxSidebarActions
        selectedSandbox={selectedSandbox}
        disabledPivot={disabledSandbox || !hasSandboxSearch}
        disabledShare={disabledSandbox || !hasSandboxSearch}
        disabledSandbox={!hasSandboxSearch}
        disabledHistory={!hasSandboxSearch}
        disabledReport={!hasSandboxSearch}
      />
    </Container>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      padding: styles.spacing.sm,
      position: 'relative',
      border: `4px solid ${styles.color.purple}`,
    },
    unselectedSandbox: {
      opacity: 0.7,
      cursor: 'pointer',
      border: 'none',
    },
    title: {
      textAlign: 'center',
      textTransform: 'capitalize',
      fontSize: '2rem',
      fontWeight: styles.font.weight.bold,
      display: 'inline-block',
      verticalAlign: 'middle',
      marginRight: styles.spacing.tiny,
    },
    subDetail: {
      marginTop: 16,
      gap: 16,
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      fontSize: '1.4rem',
    },
  },
  light: {
    summaryDesc: {
      color: styles.color.black,
    },
  },
  dark: {
    summaryDesc: {
      color: styles.color.white,
    },
  },
});
export default SandboxSidebar;
