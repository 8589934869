import { useEffect, useState } from 'react';
import { TeamActivityRow } from '../types';
import api from 'services/api';
import { getErrorMessage } from 'utils/error';
import { RequestError } from 'utils/error';

type IData = {
  timestamp: string;
  eventType: string;
  user: string;
  metadata: string;
};

function useTeamActivity(defaultParams: Record<string, any> = {}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean | string>(false);
  const [data, setData] = useState<TeamActivityRow[]>([]);
  const [more, setMore] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(50);
  const [nextOffset, setNextOffset] = useState<string | null>(null);

  const fetchTeamActivity = async (params: Record<string, any> = {}, isReset?: boolean) => {
    if (!!isReset) {
      setData([]);
      setMore(false);
      setNextOffset(null);
      setLoading(false);
    }

    setLoading(true);
    setError(false);

    try {
      const activities = await api.getTeamActivity({
        '-event_type': 'scan_create',
        ...params,
        ...(!isReset && nextOffset ? { offset: nextOffset } : {}),
      });

      if (activities.status !== 200) {
        setError('Error fetching team activity');
        return;
      }

      setLimit(activities.data.limit);
      setMore(activities.data.hasMore);
      setNextOffset(activities.data.offset);
      setData(
        ([] as IData[]).concat(
          !isReset ? data : [],
          activities.data.result.map((activity) => {
            return {
              timestamp: activity.event_timestamp,
              eventType: activity.event_type,
              user:
                activity?.user?.name && activity?.user?.email
                  ? `${activity.user.name} <${activity.user.email}>`
                  : '-',
              metadata: JSON.stringify(activity),
            };
          })
        )
      );
    } catch (error: any) {
      setError(getErrorMessage(error as RequestError));
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchTeamActivity(defaultParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    error,
    data,
    more,
    limit,
    refetch: fetchTeamActivity,
    reset: () => {
      setData([]);
      setMore(false);
      setNextOffset(() => null);
      setError(false);
      setLoading(false);
    },
  };
}
export type TeamActivityProps = ReturnType<typeof useTeamActivity>;
export default useTeamActivity;
