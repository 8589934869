import React, { useState } from 'react';
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import uniq from 'lodash/uniq';
import { TableCell } from '@material-ui/core';
import { Page } from 'models/Page';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import Popover from 'views/components/Popover';
import Icon from 'views/components/Icon';
import { ILimitedAccessMember } from 'models/Team';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import CustomTableHead from 'views/components/table/CustomTableHead';
import CustomTable from 'views/components/table/CustomTable';
import { Row } from 'views/components/table/CustomTable';
import styles from 'views/styles';
import { IColumnSort } from 'views/components/table/InfiniteScrollTable';
import TeamLimitedAccessRow from './TeamLimitedAccessRow';

interface TeamLimitedAccessTableProps {
  teamAccountNumber: number | string;
  teamMembers: Page<ILimitedAccessMember>;
  sort?: IColumnSort<keyof ILimitedAccessMember>;
  onSort?: (orderBy: keyof ILimitedAccessMember) => void;
  archiveApiKey: (id: string, key: string) => void;
  onLoadMore?: () => Promise<void>;
}

const TeaamLimitedAccessTable = ({
  teamAccountNumber,
  teamMembers,
  archiveApiKey,
}: TeamLimitedAccessTableProps) => {
  const [filteredTeams, setFilteredTeams] = useState<string[]>([]);
  const [filteredEmails, setFilteredEmails] = useState<string[]>([]);
  const [filteredCommunity, setFilteredCommunity] = useState<string[]>([]);
  const [filteredType, setFilteredType] = useState<string[]>([]);
  const { classes } = useStyles();

  const rows: Row<ILimitedAccessMember>[] = [
    {
      id: 'user.email',
      numeric: false,
      label: 'Email',
      sortable: true,
    },
    {
      id: 'account_number',
      numeric: true,
      label: 'Team',
      sortable: false,
    },
    {
      id: 'community',
      numeric: false,
      label: 'Is Private',
      sortable: true,
    },
    {
      id: 'type',
      numeric: false,
      label: 'Key Type',
      sortable: true,
    },
    {
      id: 'expiration',
      numeric: false,
      label: 'Expiration Date',
      sortable: true,
    },
  ];

  const teams = uniq(teamMembers.results.map((item) => item?.team_external?.name).filter(Boolean));
  const emails = uniq(teamMembers.results.map((item) => item?.user.email).filter(Boolean));

  const filterArrays = [filteredTeams, filteredEmails, filteredCommunity, filteredType].filter(
    (item) => item.length > 0
  );

  const rowResults = teamMembers.results.filter((item) => {
    if (filterArrays.length === 0) return true;

    return (
      (filteredTeams.length === 0 || filteredTeams.includes(item.team_external?.name ?? '')) &&
      (filteredEmails.length === 0 || filteredEmails.includes(item.user.email)) &&
      (filteredCommunity.length === 0 || filteredCommunity.includes(item.community)) &&
      (filteredType.length === 0 ||
        filteredType
          .map((type) => (type === 'UI' ? 'portal-external' : 'external'))
          .includes(item.type as 'portal-external' | 'external'))
    );
  });

  return (
    <>
      <CustomTable<ILimitedAccessMember>
        rows={rowResults}
        renderTableHead={() => (
          <CustomTableHead
            rows={rows}
            showActions={true}
            actionAlign='left'
            renderHeaderCell={(row) => {
              return (
                <TableCell width={row.width} key={row.id} align={row.numeric ? 'right' : 'left'}>
                  <div css={classes.cellContainer}>
                    {row.label}
                    {row.id === 'account_number' && (
                      <Popover
                        placement='bottom-start'
                        button={
                          <Tooltip title='Filter by team' placement='top'>
                            <div css={classes.iconContainer}>
                              <Icon
                                css={[classes.icon, filteredTeams.length && classes.iconActive]}
                                name='dropdown'
                              />
                            </div>
                          </Tooltip>
                        }
                        content={
                          <div css={classes.popover}>
                            <div
                              style={{ height: `${teams.length * 4.75}rem` }}
                              css={classes.listColumns}
                            >
                              {teams.map((item) => (
                                <div css={classes.pane} key={item}>
                                  <label>
                                    <Checkbox
                                      color='primary'
                                      checked={filteredTeams.includes(item ?? '')}
                                      onChange={(_e) => {
                                        const updateTeams = filteredTeams.includes(item ?? '')
                                          ? filteredTeams.filter((role) => role !== item)
                                          : [...filteredTeams].concat(item ?? []);

                                        setFilteredTeams(updateTeams);
                                      }}
                                    />
                                    {item}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        }
                      />
                    )}
                    {row.id === 'user.email' && (
                      <Popover
                        placement='bottom-start'
                        button={
                          <Tooltip title='Filter by email' placement='top'>
                            <div css={classes.iconContainer}>
                              <Icon
                                css={[classes.icon, filteredEmails.length && classes.iconActive]}
                                name='dropdown'
                              />
                            </div>
                          </Tooltip>
                        }
                        content={
                          <div css={classes.popover}>
                            <div
                              style={{ height: `${emails.length * 4.75}rem` }}
                              css={classes.listColumns}
                            >
                              {emails.map((item) => (
                                <div css={classes.pane} key={item}>
                                  <label>
                                    <Checkbox
                                      color='primary'
                                      checked={filteredEmails.includes(item ?? '')}
                                      onChange={(_e) => {
                                        filteredEmails.includes(item ?? '')
                                          ? setFilteredEmails(
                                              filteredEmails.filter((email) => email !== item)
                                            )
                                          : setFilteredEmails(
                                              [...filteredEmails].concat(item ?? [])
                                            );
                                      }}
                                    />
                                    {item}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        }
                      />
                    )}
                    {row.id === 'community' && (
                      <Popover
                        placement='bottom-start'
                        button={
                          <Tooltip title='Filter by community' placement='top'>
                            <div css={classes.iconContainer}>
                              <Icon
                                css={[classes.icon, filteredCommunity.length && classes.iconActive]}
                                name='dropdown'
                              />
                            </div>
                          </Tooltip>
                        }
                        content={
                          <div css={classes.popover}>
                            <div
                              style={{ textTransform: 'capitalize', height: `${2 * 4.75}rem` }}
                              css={classes.listColumns}
                            >
                              {['public', 'private'].map((item) => (
                                <div css={classes.pane} key={item}>
                                  <label>
                                    <Checkbox
                                      color='primary'
                                      checked={filteredCommunity.includes(item ?? '')}
                                      onChange={(_e) => {
                                        filteredCommunity.includes(item ?? '')
                                          ? setFilteredCommunity(
                                              filteredCommunity.filter(
                                                (community) => community !== item
                                              )
                                            )
                                          : setFilteredCommunity(
                                              [...filteredCommunity].concat(item ?? [])
                                            );
                                      }}
                                    />
                                    {item}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        }
                      />
                    )}
                    {row.id === 'type' && (
                      <Popover
                        placement='bottom-start'
                        button={
                          <Tooltip title='Filter by type' placement='top'>
                            <div css={classes.iconContainer}>
                              <Icon
                                css={[classes.icon, filteredType.length && classes.iconActive]}
                                name='dropdown'
                              />
                            </div>
                          </Tooltip>
                        }
                        content={
                          <div css={classes.popover}>
                            <div style={{ height: `${2 * 4.75}rem` }} css={classes.listColumns}>
                              {['UI', 'API'].map((item) => (
                                <div css={classes.pane} key={item}>
                                  <label>
                                    <Checkbox
                                      color='primary'
                                      checked={filteredType.includes(item ?? '')}
                                      onChange={(_e) => {
                                        filteredType.includes(item ?? '')
                                          ? setFilteredType(
                                              filteredType.filter((type) => type !== item)
                                            )
                                          : setFilteredType([...filteredType].concat(item ?? []));
                                      }}
                                    />
                                    {item}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        }
                      />
                    )}
                  </div>
                </TableCell>
              );
            }}
          />
        )}
        renderTableRow={(row: ILimitedAccessMember) => {
          return (
            <TeamLimitedAccessRow
              key={row.account_number}
              rowData={row}
              teamMembers={teamMembers}
              archiveApiKey={archiveApiKey}
            />
          );
        }}
      />
    </>
  );
};

const useStyles = makeStyles({
  base: {
    iconContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
    popover: {
      padding: '2rem 5rem 2rem 2rem',
      borderRadius: '0.5rem',
      marginTop: '1rem',
      fontWeight: 600,
      border: `1px solid ${styles.color.lightGrey}`,
    },
    icon: {
      fontSize: '0.6rem',
    },
    listColumns: {
      marginTop: '1rem',
    },
    pane: {
      '&:hover': {
        cursor: 'grab',
      },
    },
    cellContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
    },
  },
  light: {
    icon: {
      color: styles.color.black,
    },
    iconActive: {
      color: styles.color.purple,
    },
    popover: {
      backgroundColor: styles.color.ulGrey,
    },
  },
  dark: {
    icon: {
      color: styles.color.greyishPurple,
    },
    iconActive: {
      color: styles.color.lightBlue,
    },
    popover: {
      backgroundColor: styles.color.darkOffBlack,
    },
  },
});
export default TeaamLimitedAccessTable;
