// @ts-nocheck
import React, { useState, createContext, useContext, useCallback, useEffect, useMemo } from 'react';
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import Button from '@material-ui/core/Button';
import { useHistory, useRouteMatch, useLocation, Route, Switch, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import qs from 'query-string';
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';
import reduce from 'lodash/reduce';
import compareDesc from 'date-fns/compareDesc';
import { Dispatch } from 'state/types';
import { SandboxActionName } from 'state/sandbox/types';
import { RootState } from 'state/root';
import { loadingSelector, errorSelector } from 'state/requests/selectors';
import { getLatestSandboxesByHash, getSandboxTaskByHashAndId } from 'state/sandbox/actions';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Card from 'views/components/layout/Card';
import Panel from 'views/components/layout/Panel';
import { GoToTop } from 'views/components/GoToTop';
import PanelLoader from 'views/components/Loader/PanelLoader';
import PageSidebarLayout from 'views/components/layout/PageSidebarLayout';
import PivotingFilter from 'views/components/Pivoting/Filter';
import { usePivoting } from 'views/components/providers/PivotingProvider';
import { getAccountContext } from 'state/account/actions';
import { ContextAccount, readContextAccount } from 'state/auth/selectors';
import { isDateBefore } from 'utils/date/date';
import { SandboxInstanceStatus, SandboxProviders, Sandbox, SandboxReport } from 'models/Sandbox';
import { useAuth } from 'views/components/providers/AuthProvider';
import useHasFeature from 'hooks/useHasFeature';
import Icon from 'views/components/Icon';
import NetworkTab, { getNetworkData } from './NetworkTab';
import JSONTab from './JSONTab';
import DroppedTab, { getDroppedFilesData } from './DroppedTab';
import AnalysisTab, { getAnalysisData } from './AnalysisTab';
import ExtractedConfigTab, { getExtractedConfigData } from './ExtractedConfigTab';
import ArtifactDownloadTab from './ArtifactDownloadTab';
import HttpRequestsTab, { getHttpData } from './HttpRequestsTab';
import VideoTab from './VideoTab';
import ScreenshotTab from './ScreenshotTab';
import EmptySandboxing from './SandboxPanel/SandboxTabs/EmptySandboxing';
import PaginatedTabs from 'views/components/PaginatedTabs/PaginatedTabs';
import { parseSandboxURL, parseScanPageURL } from 'views/url';
import ErrorPageContent from 'views/components/error/ErrorPageContent';
import { useUser } from 'views/components/providers/UserProvider';
import SandboxSidebar from './SandboxSidebar';

const getSortedSandboxList = (list, sandboxId) => {
  const cleanedUpSandboxes = list.filter(
    (item) => !!item && (item.status === SandboxInstanceStatus.SUCCEEDED || item.id === sandboxId)
  );

  const groupBySandbox = groupBy(cleanedUpSandboxes, (item) => item.sandbox);

  const sortedBySandboxWithLatest = reduce(
    groupBySandbox,
    (acc, value, key) => {
      const ordered = value.sort((a, b) => {
        return compareDesc(new Date(a.created), new Date(b.created));
      });

      return { ...acc, [key]: ordered };
    },
    {}
  );
  return sortedBySandboxWithLatest;
};

const UnAuthSandboxPanel = () => {
  return <EmptySandboxing type='detailsPage' forbidden='notLoggedIn' />;
};

export const getSandboxAvailability = (status) =>
  [
    SandboxInstanceStatus.PENDING,
    SandboxInstanceStatus.STARTED,
    SandboxInstanceStatus.SUCCEEDED,
  ].includes(status);

const SandboxPanel = () => {
  const { classes } = useStyles();
  const history = useHistory();
  const location = useLocation<{ sandboxId?: string } | undefined>();
  const match = useRouteMatch<{ artifactType: string; sha256: string }>();
  const { requests } = useSelector((state: RootState) => state);
  const error = errorSelector(requests, [SandboxActionName.GET_SANDBOXES_HASH]);
  const isLoading = loadingSelector(requests, [
    SandboxActionName.GET_SANDBOXES_HASH,
    SandboxActionName.GET_SANDBOX_TASK_ID,
  ]);
  const { active: isPivotActive } = usePivoting();
  const user = useUser();
  const { hasFeature: hasSandboxSearch, checkFeature } = useHasFeature('sandbox_search');

  const { isAuthenticated } = useAuth();

  const { selectedSandbox } = useContext(SandboxSelectionContext);
  const isVideoTab = location.pathname.endsWith('video');

  const sha256 = match.params?.sha256 ?? '';
  const sandboxType = match.params?.artifactType ?? '';

  const { data: httpData, hasPresentValues: hasHttpData } = useMemo(
    () => getHttpData(selectedSandbox?.report ?? {}, selectedSandbox.sandbox),
    [selectedSandbox?.report, selectedSandbox.sandbox]
  );
  const { analysisData, hasPresentValues: hasAnalysisData } = useMemo(
    () => getAnalysisData(selectedSandbox?.report, selectedSandbox.sandbox),
    [selectedSandbox?.report, selectedSandbox.sandbox]
  );
  const { data: networkData, hasPresentValues: hasNetworkData } = useMemo(
    () => getNetworkData(selectedSandbox?.report, selectedSandbox.sandbox),
    [selectedSandbox?.report, selectedSandbox.sandbox]
  );
  const { extractedConfigData, hasPresentValues: hasExtractedConfigData } = useMemo(
    () => getExtractedConfigData(selectedSandbox?.report, selectedSandbox.sandbox),
    [selectedSandbox?.report, selectedSandbox.sandbox]
  );
  const { data: droppedFilesData, hasData: hasDroppedFilesData } = useMemo(
    () => getDroppedFilesData(selectedSandbox?.report, selectedSandbox.sandbox),
    [selectedSandbox?.report, selectedSandbox.sandbox]
  );

  const recordingVideo = useMemo(
    () => selectedSandbox?.sandboxArtifacts?.find((item) => item?.type === 'recording') ?? {},
    [selectedSandbox.sandboxArtifacts]
  );

  const screenshots = useMemo(
    () => selectedSandbox?.sandboxArtifacts?.filter((item) => item?.type === 'screenshot') ?? {},
    [selectedSandbox.sandboxArtifacts]
  );

  const isVideoExpired = useMemo(
    () =>
      !isEmpty(selectedSandbox) &&
      isDateBefore(new Date(selectedSandbox.created), new Date(2024, 5, 30)),
    [selectedSandbox]
  );

  const urls = Object.assign(
    {
      home: parseSandboxURL(sha256, { sandboxType }),
      network: parseSandboxURL(sha256, { sandboxType, section: 'network' }),
      fileData: parseSandboxURL(sha256, { sandboxType, section: 'file-data' }),
      extractedConfig: parseSandboxURL(sha256, { sandboxType, section: 'extracted-config' }),
      dropped: parseSandboxURL(sha256, { sandboxType, section: 'dropped' }),
      json: parseSandboxURL(sha256, { sandboxType, section: 'json' }),
      analysis: parseSandboxURL(sha256, { sandboxType, section: 'analysis' }),
      httpRequests: parseSandboxURL(sha256, { sandboxType, section: 'http' }),
      download: parseSandboxURL(sha256, { sandboxType, section: `download` }),
    },
    selectedSandbox?.sandbox === SandboxProviders.TRIAGE && {
      video: parseSandboxURL(sha256, { sandboxType, section: `video` }),
    },
    selectedSandbox?.sandbox === SandboxProviders.CAPE && {
      screenshots: parseSandboxURL(sha256, { sandboxType, section: `screenshots` }),
    }
  );

  const isActiveTab = (tab) => location.pathname.includes(`/${tab}`);

  const tabs = [
    {
      value: urls.extractedConfig,
      label: 'Extracted Config',
      dataTestId: 'sandboxExtractedConfig',
      disabled: !hasExtractedConfigData && !isLoading,
      active: isActiveTab('extracted-config'),
    },
    {
      value: urls.dropped,
      label: 'Dropped Files',
      dataTestId: 'sandboxDropped',
      disabled: !hasDroppedFilesData && !isLoading,
      active: isActiveTab('dropped'),
    },
    {
      value: urls.network,
      label: 'Network',
      dataTestId: 'sandboxNetwork',
      disabled: !hasNetworkData && !isLoading,
      active: isActiveTab('network'),
    },
    selectedSandbox?.sandbox === SandboxProviders.TRIAGE && {
      value: urls.httpRequests,
      label: 'HTTP Requests',
      dataTestId: 'httpRequests',
      disabled: !hasHttpData && !isLoading,
      active: isActiveTab('http'),
    },
    {
      value: urls.analysis,
      label: 'Analysis',
      dataTestId: 'analysis',
      disabled: !hasAnalysisData && !isLoading,
      active: isActiveTab('analysis'),
    },
    {
      value: urls.json,
      label: 'JSON',
      dataTestId: 'sandboxJson',
      disabled: isPivotActive || (!selectedSandbox?.report && !isLoading),
      disabledText: isPivotActive ? 'Pivot Active' : undefined,
      active: isActiveTab('json'),
    },
    {
      value: urls.download,
      label: 'Download Results',
      dataTestId: 'sandboxDownload',
      disabled: isPivotActive || (!selectedSandbox?.report && !isLoading),
      disabledText: isPivotActive ? 'Pivot Active' : undefined,
      active: isActiveTab('download'),
    },
    selectedSandbox?.sandbox === SandboxProviders.TRIAGE && {
      value: urls.video,
      label: 'Video Playback',
      dataTestId: 'sandboxVideo',
      disabledText:
        (isPivotActive && 'Pivot Activate') ||
        (isVideoExpired
          ? 'The recording cannot be played here, but is still downloadable in the Downloads tab.'
          : undefined),
      disabled:
        (isPivotActive && 'Pivot Activate') ||
        (isVideoExpired
          ? isVideoExpired
          : !!recordingVideo &&
            ((!selectedSandbox?.report && !isLoading) || !recordingVideo.instanceId)),
      active: isActiveTab('video'),
    },
    selectedSandbox?.sandbox === SandboxProviders.CAPE && {
      value: urls.screenshots,
      label: 'Screenshots',
      dataTestId: 'sandboxImages',
      disabledText: isPivotActive ? 'Pivot Active' : undefined,
      disabled: isPivotActive || (isEmpty(screenshots) && !selectedSandbox?.report && !isLoading),
      active: isActiveTab('screenshots'),
    },
  ].filter(Boolean);

  const _handleChange = (_: React.ChangeEvent<any>, pathname: string) => {
    history.push(`${pathname}${location?.search ?? ''}`);
  };

  const checkLoadingState = useCallback(
    (Component) => {
      if (isLoading) {
        return <PanelLoader />;
      }

      if (isAuthenticated && user.context.isLimitedAccess && !hasSandboxSearch) {
        return (
          <EmptySandboxing
            type='custom'
            title='Access Restricted'
            infoText={
              <>
                <p>
                  It looks like you don’t currently have access to the Sandboxing functionality.
                </p>
                <p>Please contact your administrator to enable this feature.</p>
              </>
            }
          />
        );
      }

      return !isAuthenticated ? (
        <UnAuthSandboxPanel />
      ) : getSandboxAvailability(selectedSandbox.status) ? (
        Component
      ) : (
        <EmptySandboxing
          type='custom'
          title='No Sandbox results for this artifact.'
          infoText='Click the Re-Sandboxing button to trigger a sandbox detonation for this artifact.'
        />
      );
    },
    [isAuthenticated, isLoading, selectedSandbox.status, hasSandboxSearch, user.context]
  );

  const networkError = Object.assign(
    !!error && getSandboxAvailability(selectedSandbox.status) ? {} : { error }
  );

  const NetworkTabComponent = useCallback(
    () =>
      checkLoadingState(
        <NetworkTab
          status={selectedSandbox.status}
          provider={selectedSandbox.sandbox}
          data={networkData}
          {...networkError}
        />
      ),
    [networkError, checkLoadingState, selectedSandbox.status, selectedSandbox.sandbox, networkData]
  );

  const HttpRequestsComponent = useCallback(
    () => checkLoadingState(<HttpRequestsTab provider={selectedSandbox.sandbox} data={httpData} />),
    [httpData, selectedSandbox.sandbox, checkLoadingState]
  );

  const JSONTabComponent = useCallback(
    () => checkLoadingState(<JSONTab data={selectedSandbox?.report} />),
    [selectedSandbox?.report, checkLoadingState]
  );

  const AnalysisTabComponent = useCallback(
    () =>
      checkLoadingState(
        <AnalysisTab provider={selectedSandbox.sandbox} dataMapped={analysisData} />
      ),
    [analysisData, checkLoadingState, selectedSandbox.sandbox]
  );

  const DroppedTabComponent = useCallback(
    () =>
      checkLoadingState(<DroppedTab provider={selectedSandbox.sandbox} data={droppedFilesData} />),
    [droppedFilesData, checkLoadingState, selectedSandbox.sandbox]
  );

  const ExtractedConfigTabComponent = useCallback(
    () =>
      checkLoadingState(
        <ExtractedConfigTab provider={selectedSandbox.sandbox} data={extractedConfigData} />
      ),
    [checkLoadingState, extractedConfigData, selectedSandbox.sandbox]
  );

  const ArtifactDownloadTabComponent = useCallback(
    () =>
      checkLoadingState(
        <ArtifactDownloadTab
          sandboxedArtifactId={selectedSandbox.instanceId}
          sha256={sha256}
          error={error}
          status={selectedSandbox.status}
          data={selectedSandbox.sandboxArtifacts}
        />
      ),
    [
      selectedSandbox.instanceId,
      error,
      checkLoadingState,
      sha256,
      selectedSandbox.status,
      selectedSandbox.sandboxArtifacts,
    ]
  );

  const VideoTabComponent = useCallback(
    () => checkLoadingState(<VideoTab sandboxedArtifactId={recordingVideo.instanceId} />),
    [recordingVideo, checkLoadingState]
  );

  const ScreenshotTabComponent = useCallback(
    () =>
      checkLoadingState(<ScreenshotTab artifactIds={screenshots.map((item) => item.instanceId)} />),
    [screenshots, checkLoadingState]
  );

  if (!selectedSandbox?.sandbox && error?.message === 'invalid_sandbox' && isAuthenticated) {
    return (
      <Card style={{ padding: '5rem' }}>
        <ErrorPageContent
          heading='Sandbox not found'
          text={`The sandbox with the ID ${sha256} was not found.`}
        >
          <Button
            data-cy='scanSearchRetry'
            style={{ fontSize: '2.4rem' }}
            color='primary'
            variant='contained'
            onClick={() => window.location.replace('/sandbox')}
          >
            Go to All Sandboxing
          </Button>
        </ErrorPageContent>
      </Card>
    );
  }

  if (!selectedSandbox?.sandbox || isLoading) {
    return null;
  }

  const defaultTab = tabs.find((item) => !item.disabled);

  return (
    <Card>
      <PaginatedTabs
        tabs={tabs}
        maxTabs={5}
        minTabs={3}
        indicatorColor='primary'
        value={location.pathname}
        onChange={_handleChange}
      >
        {user.context?.isLimitedAccess && !checkFeature('scan') ? null : (
          <a
            css={[classes.link, !isAuthenticated && classes.disabledElement]}
            className='a'
            href={parseScanPageURL(sha256, {
              type: sandboxType,
              fallbackId: selectedSandbox.instanceId,
            })}
            onClick={(e) => {
              window.open(
                parseScanPageURL(sha256, {
                  type: sandboxType,
                  fallbackId: selectedSandbox.instanceId,
                }),
                '_blank'
              );
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            Latest Scan Results
            <Icon disabled={!isAuthenticated} className='icon' name='open-view' />
          </a>
        )}
      </PaginatedTabs>
      <Panel>
        <Switch>
          <Redirect exact from={urls.home} to={`${defaultTab.value}${location?.search}`} />
          <Route exact path={urls.extractedConfig} render={ExtractedConfigTabComponent} />
          <Route exact path={urls.dropped} render={DroppedTabComponent} />
          <Route exact path={urls.network} render={NetworkTabComponent} />
          <Route exact path={urls.analysis} render={AnalysisTabComponent} />
          <Route exact path={urls.httpRequests} render={HttpRequestsComponent} />
          <Route exact path={urls.json} render={JSONTabComponent} />
          <Route exact path={urls.download} render={ArtifactDownloadTabComponent} />
          {selectedSandbox?.sandbox === SandboxProviders.TRIAGE && !isVideoExpired && (
            <Route exact path={urls.video} render={VideoTabComponent} />
          )}
          {selectedSandbox?.sandbox === SandboxProviders.CAPE && (
            <Route exact path={urls.screenshot} render={ScreenshotTabComponent} />
          )}
          {isVideoTab ? (
            <Redirect
              to={
                isVideoExpired
                  ? `${urls.download}${location?.search}`
                  : `${urls.network}${location?.search}`
              }
            />
          ) : (
            <Redirect to='/404' />
          )}
        </Switch>
        <GoToTop />
      </Panel>
    </Card>
  );
};

export const SandboxSelectionContext = createContext<{
  selectedSandbox: Sandbox<SandboxReport>;
  selectSandboxId: () => void;
}>({
  selectedSandbox: {},
  selectSandboxId: () => null,
});

const SandboxDetails = () => {
  const { active: isPivotActive } = usePivoting();
  const dispatch = useDispatch<Dispatch>();
  const match = useRouteMatch<{ artifactType: string; sha256: string }>();
  const location = useLocation<{ sandboxId?: string } | undefined>();
  const [selectedSandboxId, selectSandboxId] = useState('');
  const sha256: string = match.params?.sha256;
  const { sandboxId = '' } = qs.parse(location.search);

  const sandboxList = useSelector((state: RootState) => state.sandbox.list);
  const sortedSandboxList = getSortedSandboxList(sandboxList, sandboxId);
  const initialSandbox = !isEmpty(sandboxId)
    ? sandboxList.find((item) => item.id === sandboxId) ?? {}
    : Object.values(sortedSandboxList)?.flat()?.[0] ?? {};

  const selectedSandbox = !!selectedSandboxId
    ? sandboxList.find((item) => item.id === selectedSandboxId) ?? initialSandbox
    : initialSandbox;

  const getLatestSandbox = useCallback(
    (sandoxId) => {
      if (!!sandboxId) {
        dispatch(getSandboxTaskByHashAndId(sha256, sandboxId));
      }

      dispatch(getLatestSandboxesByHash(sha256));
    },
    [sandboxId, sha256, dispatch]
  );

  useEffect(() => {
    getLatestSandbox(sandboxId);
  }, [getLatestSandbox, sandboxId]);

  const _refreshContext = useCallback(async (ctx?: ContextAccount) => {
    await dispatch(getAccountContext(ctx));
    // TODO a race condition with UserProvider cause this to not work if not called twice :/
    await dispatch(getAccountContext(ctx));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedSandbox?.contextAccount?.endsWith('-private')) {
      _refreshContext(readContextAccount(selectedSandbox.contextAccount));
    }
  }, [selectedSandbox?.contextAccount, _refreshContext]);

  return (
    <SandboxSelectionContext.Provider
      value={{ topSandbox: initialSandbox, sortedSandboxList, selectedSandbox, selectSandboxId }}
    >
      {isPivotActive && <PivotingFilter />}
      <PageSidebarLayout renderSidebar={SandboxSidebar} renderPanel={SandboxPanel} />
    </SandboxSelectionContext.Provider>
  );
};

const useStyles = makeStyles({
  base: {
    link: {
      marginRight: '1.5rem',
      marginTop: '0.5rem',
      display: 'flex',
      alignItems: 'center',
      gap: '1.5rem',
      fontWeight: 600,
      fontSize: '1.5rem',
      '& .icon': {
        fontSize: '0.7rem',
      },
    },
    disabledElement: {
      cursor: 'not-allowed',
      color: `${styles.color.grey} !important`,
    },
  },
});
export default SandboxDetails;
