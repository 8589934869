// @ts-nocheck
import React, { useCallback, useEffect } from 'react';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import {
  RegisterOptions,
  useForm,
  Controller,
  FormProvider,
  useFormContext,
  UseFormReturn,
  Form as FormComponent,
} from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import TextField from '@material-ui/core/TextField/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import MUISelect from '@material-ui/core/Select';
import Select from 'views/components/Select';
import DateSelector from 'views/components/DateSelector';
import { ObjectInterpolation } from '@emotion/core';
import Switch from '@material-ui/core/Switch';
import { FormControl, FormControlLabel } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';

export enum ELEMENT_TYPE {
  select = 'select',
  selectTag = 'selectTag',
  input = 'input',
  inputTag = 'inputTag',
  dateSelect = 'dateSelect',
  switch = 'switch',
  checkbox = 'checkbox',
  textarea = 'textarea',
  inputMultiple = 'inputMultiple',
}

enum DateOptions {
  TODAY = 'TODAY',
  DAYS = 'DAYS',
}

interface IManualItem {
  elementType:
    | keyof typeof ELEMENT_TYPE
    | {
        name: keyof typeof ELEMENT_TYPE;
        type: 'text' | 'number' | 'password' | 'date' | 'checkbox';
      };
  name: string;
  id: string;
  placeholder: string;
  defaultValue:
    | string
    | number
    | boolean
    | string[]
    | { [key: string]: string | string[] | number | keyof typeof DateOptions };
  setValue: (data: any) => any;
  defaultOptions: string[];
  inputOptions: { [key: string]: string | number };
  hideName: boolean;
  bold: boolean;
  show: boolean | ((data: any) => boolean);
  maxChars: number; // textarea component
  rules: RegisterOptions;
}

type CombinedElements<T> = [T, T];

export type IManual =
  | Partial<IManualItem>
  | { name: string; combine: CombinedElements<Partial<IManualItem>> };

export type FormReturn = UseFormReturn<TFormValues>;

type FooterRenderType = FormReturn & FormReturn['formState']['dirtyFields'];

type FormProps = {
  filters?: {
    [key: string]:
      | string
      | string[]
      | number
      | boolean
      | { value: string | number; option: string };
  };
  onSubmit?: (
    data: any,
    clearForm: FormReturn['reset'],
    formProps: Omit<FormReturn, 'formState'>
  ) => void;
  customInputs?: (data: any) => JSX.Element;
  footerRender?: (data: FooterRenderType) => JSX.Element;
  headerRender?: (data: {
    resetForm: FormReturn['reset'];
    dirtyFields: FormReturn['formState']['dirtyFields'];
  }) => JSX.Element;
  onFormDirtyFields?: (data: FormReturn['formState']['dirtyFields']) => void;
  isFormInclusive?: boolean;
  customClass?: ObjectInterpolation<any>;
};

const DateSelectorComponent = ({ item }) => {
  const { register, watch, setValue } = useFormContext();
  const dateValue = watch(item.id);

  useEffect(() => {
    register(item.id, { value: dateValue });
  }, [register]); // eslint-disable-line

  const option = dateValue?.option;
  const value = dateValue?.value;

  return (
    <DateSelector
      label={item.name}
      onChange={(value, dateValue) => {
        setValue(item.id, { value, option: dateValue }, { shouldDirty: true, shouldTouch: true });
      }}
      maxdays={option === DateOptions.TODAY ? '' : value}
      defaultValue={value}
      defaultOption={option}
    />
  );
};

const TextInputComponent = ({ item }) => {
  const { control, getValues, setValue } = useFormContext();

  const { elementType = '' } = getElementProps(item);
  const registerValueType =
    !!elementType && elementType === 'number' ? { valueAsNumber: true } : {};

  const defaultValue = getValues()[item.id] ?? '';

  const value =
    isEmpty(defaultValue) && typeof item?.setValue === 'function'
      ? item.setValue(getValues())
      : defaultValue;

  useEffect(() => {
    if (isEmpty(defaultValue) && typeof item?.setValue === 'function') {
      setValue(item.id, value);
    }
  }, [defaultValue, value, item.id]); // eslint-disable-line

  const valueProps = item.hasOwnProperty('inputOptions')
    ? {
        InputProps: { inputProps: item?.inputOptions },
      }
    : {};

  return (
    <Controller
      control={control}
      name={item.id}
      rules={registerValueType}
      render={({ field, formState }) => {
        const { value: fieldValue, onChange: onFieldChange, ...otherField } = field;

        return (
          <TextField
            {...otherField}
            value={fieldValue ?? value}
            onChange={(e) => {
              onFieldChange(e.target.value);
            }}
            label={item.placeholder}
            type={elementType ?? 'text'}
            fullWidth
            color='primary'
            {...(elementType === 'date'
              ? {
                  InputLabelProps: {
                    shrink: true,
                  },
                  helperText: 'MM-DD-YYYY',
                  variant: 'outlined',
                }
              : {})}
            {...valueProps}
          />
        );
      }}
    />
  );
};

const AutocompleteInputComponent = ({ item }) => {
  const { control, trigger, setError } = useFormContext();

  const valueProps = item.hasOwnProperty('inputOptions')
    ? {
        InputProps: { inputProps: item?.inputOptions },
      }
    : {};

  return (
    <Controller
      control={control}
      name={item.id}
      rules={item?.rules ?? {}}
      render={({ field, formState }) => {
        const { errors } = formState;
        const { value: fieldValue, onChange: onFieldChange, ...otherField } = field;

        return (
          <Autocomplete
            ref={otherField.ref}
            value={fieldValue}
            multiple
            id='autocomplete-filled'
            options={[]}
            freeSolo
            fullWidth
            ChipProps={{ color: 'primary' }}
            onChange={(e, value, reason) => {
              onFieldChange(value);
              trigger(item.id);
            }}
            onBlur={(e) => {
              !!e.target.value &&
                setError(item.id, { type: 'manual', message: 'Press ENTER to add' });
            }}
            renderInput={(params) => {
              const errorState = !isEmpty(errors[item.id]) ? errors[item.id] : {};
              return (
                <TextField
                  error={!isEmpty(errorState)}
                  helperText={errorState.message}
                  onBlur={otherField.onBlur}
                  label={item.placeholder}
                  fullWidth
                  {...valueProps}
                  {...params}
                />
              );
            }}
          />
        );
      }}
    />
  );
};

const TextInputTagComponent = ({ item }) => {
  const ref = React.useRef();
  const { register, getValues, setValue, watch, formState } = useFormContext();
  const value = getValues()[item.id] ?? [];
  const [tags, setTags] = React.useState<string[]>(value);
  const { classes } = useStyles();
  const watchValue = watch(item.id);

  register(item.id, { value, ...(item?.rules ?? {}) });

  const onHitEnter = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      if (!e.target.value) return;
      if (tags.includes(e.target.value)) return;

      setValue(item.id, [...tags, e.target.value], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  };

  useEffect(() => {
    // Handle reset form
    if (!watchValue) {
      setTags([]);
    }
  }, [watchValue]);

  const { errors } = formState;
  const errorState = !isEmpty(errors[item.id]) ? errors[item.id] : {};

  useEffect(() => {
    if (isEmpty(errors)) {
      setTags(watchValue);

      ref.current.value = '';
    }
  }, [errors, watchValue]);

  return (
    <div css={classes.selectTagContainer}>
      <div css={classes.selectTag}>
        {(item?.name || !isEmpty(item?.hideName)) && <span>{item.name}</span>}
        <TextField
          error={!isEmpty(errorState)}
          helperText={errorState.message}
          inputRef={ref}
          label={item.placeholder}
          type='text'
          fullWidth
          onKeyPress={onHitEnter}
        />
      </div>
      <TagsContainer tags={tags} setTags={setTags} item={item} />
    </div>
  );
};

const SelectComponent = ({ item }) => {
  const { control, getValues, watch } = useFormContext();
  const watchValue = watch(item.dependency);

  useEffect(() => {
    if (typeof item.show === 'function') {
      item.show(getValues());
    }
  }, [watchValue]); // eslint-disable-line

  const getOptionDisabled = useCallback(
    (option) => {
      return item?.getOptionDisabled(option, getValues());
    },
    [item, getValues]
  );

  const getOptionLabel = useCallback(
    (option) => {
      return item?.getOptionLabel(option, getValues());
    },
    [item, getValues]
  );

  return (
    <Controller
      control={control}
      name={item.id}
      render={({ field }) => {
        return (
          <Select
            {...field}
            getOptionDisabled={item?.getOptionDisabled ? getOptionDisabled : undefined}
            getOptionLabel={item?.getOptionLabel ? getOptionLabel : undefined}
            placeholder={item?.placeholder}
            options={item.defaultOptions}
            value={field?.value ?? item.defaultValue}
          />
        );
      }}
    />
  );
};

export const SelectTagComponent = ({ item }) => {
  const { register, getValues, setValue, watch } = useFormContext();
  const values = getValues();
  const value = values[item.id] ?? [];

  const [tags, setTags] = React.useState<string[]>(value);
  const { classes } = useStyles();
  const watchValue = watch(item.id);

  register(item.id, { value: tags });

  useEffect(() => {
    // Handle reset form
    if (!watchValue) {
      setTags([]);
    }
  }, [watchValue]);

  return (
    <div css={classes.selectTagContainer}>
      <div css={classes.selectTag}>
        <span>{item.name}</span>
        <FormControl css={classes.formControl}>
          <InputLabel id={item.id + '-select'}>{item.placeholder}</InputLabel>
          <MUISelect
            id={item.id + '-select'}
            multiple
            fullWidth
            value={tags}
            onChange={(e) => {
              setValue(item.id, e.target.value, { shouldDirty: true, shouldTouch: true });
              setTags(e.target.value);
            }}
            input={<Input />}
            renderValue={(selected) => (selected as string[]).join(', ')}
          >
            {item.defaultOptions.map((tag: string) => (
              <MenuItem key={tag} value={tag}>
                <Checkbox color='primary' checked={tags.indexOf(tag) > -1} />
                <ListItemText primary={tag} />
              </MenuItem>
            ))}
          </MUISelect>
        </FormControl>
      </div>
      <TagsContainer item={item} tags={tags} setTags={setTags} />
    </div>
  );
};

const TagsContainer = ({ item, tags, setTags }) => {
  const { setValue } = useFormContext();
  const { classes } = useStyles();

  return (
    <div css={classes.tagContainer}>
      {tags.map((tag, index) => (
        <Chip
          key={index}
          color='primary'
          onDelete={() => {
            const newTags = tags.filter((t, i) => i !== index);

            setValue(item.id, newTags, { shouldDirty: true, shouldTouch: true });
            setTags(newTags);
          }}
          label={tag}
        />
      ))}
    </div>
  );
};

const SwitchComponent = ({ item }) => {
  const { control, watch } = useFormContext();
  const watchValue = watch(item.dependency);

  const {
    defaultOptions: [falseLabel, trueLabel],
  } = item;

  useEffect(() => {
    if (typeof item.show === 'function') {
      item.show(getValues());
    }
  }, [watchValue]); // eslint-disable-line

  return (
    <Controller
      control={control}
      name={item.id}
      render={({ field }) => {
        const value =
          (typeof field.value === 'boolean' && field.value) ||
          (typeof field.value === 'string' && field.value === trueLabel)
            ? true
            : false;

        return (
          <>
            <span>{falseLabel}</span>
            <Switch {...field} color='primary' label={item.name} checked={value} />
            <span>{trueLabel}</span>
          </>
        );
      }}
    />
  );
};

const CheckboxComponent = ({ item }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={item.id}
      render={({ field }) => {
        const { value, ...otherField } = field;
        return (
          <FormControlLabel
            control={<Checkbox {...otherField} color='primary' checked={!!value} />}
            label={item.name}
          />
        );
      }}
    />
  );
};

const TextAreaComponent = ({ item }) => {
  const { control } = useFormContext();
  const { classes } = useStyles();
  const { maxChars = 500 } = item;

  const handleInputChange = (e, field) => {
    const inputText = e.target.value;
    const chars = inputText.trim().split('').filter(Boolean);

    if (chars.length <= maxChars) {
      field.onChange(inputText);
    }
  };

  return (
    <Controller
      control={control}
      name={item.id}
      rules={item.rules}
      render={({ field, formState }) => {
        const { isValid } = formState;

        const chars = (field?.value ?? '').split('').filter(Boolean).length;

        return (
          <>
            <div css={classes.textareaContainer}>
              <textarea
                {...field}
                css={classes.textarea}
                placeholder={item.placeholder}
                value={field?.value ?? item.defaultValue}
                onChange={(e) => {
                  handleInputChange(e, field);
                }}
              />
              {maxChars && (
                <div css={classes.textareaChars}>{`${chars} / ${maxChars}`} characters</div>
              )}
            </div>
            {!isValid && (
              <div style={{ marginTop: 16, textAlign: 'justify' }}>
                <div style={{ color: styles.color.red }}>Invalid Characters Detected</div>
                <span>
                  Only English letters, numbers, and the following punctuation symbols are allowed:
                  . , _ - () [] ; ? : " ' !.
                </span>
              </div>
            )}
          </>
        );
      }}
    />
  );
};

const getElementProps = (item) => {
  let typeName, typeElement;

  if (!Boolean(item.elementType)) {
    throw Error('Undefined elementType');
  } else if (typeof item.elementType === 'string') {
    typeName = item.elementType;
  } else if (typeof item.elementType === 'object') {
    typeName = item.elementType.name;
    typeElement = item.elementType.type;
  } else {
    throw Error('Wrong data type');
  }
  return { elementName: typeName, elementType: typeElement };
};

const generateComponent = (item) => {
  const { elementName } = getElementProps(item);

  switch (elementName) {
    case 'input': {
      return () => {
        return <TextInputComponent item={item} />;
      };
    }
    case 'inputTag': {
      return () => {
        return <TextInputTagComponent item={item} />;
      };
    }
    case 'inputMultiple': {
      return () => <AutocompleteInputComponent item={item} />;
    }
    case 'select': {
      return () => {
        return <SelectComponent item={item} />;
      };
    }
    case 'selectTag': {
      return () => {
        return <SelectTagComponent item={item} />;
      };
    }
    case 'switch': {
      return () => {
        return <SwitchComponent item={item} />;
      };
    }
    case 'dateSelect': {
      return () => {
        return <DateSelectorComponent item={item} />;
      };
    }
    case 'checkbox': {
      return () => {
        return <CheckboxComponent item={item} />;
      };
    }
    case 'textarea': {
      return () => {
        return <TextAreaComponent item={item} />;
      };
    }

    default: {
      throw Error('Unknown element type. Element types allowed: input, select, dateSelect');
    }
  }
};

export const ElementComponent = ({ item, isFormInclusive }) => {
  const { classes } = useStyles();

  const Element = React.useMemo(
    () => generateComponent(item),
    [item] // eslint-disable-line
  );

  return (
    <div
      css={[isFormInclusive ? classes.simpleSingleInputContainer : classes.singleInputContainer]}
    >
      <Element />
    </div>
  );
};

export const CombineComponent = ({ item }) => {
  const { getValues } = useFormContext();
  const { classes } = useStyles();

  const combineItems =
    typeof item.combine === 'function' ? item.combine(getValues()) : item.combine;

  return (
    <div css={classes.combinedInputContainer}>
      {combineItems.map((item) => {
        return <ElementComponent key={item.id} item={item} />;
      })}
    </div>
  );
};

const getDefaultValues = (items) => {
  return items
    .filter((item) => item.show !== false)
    .reduce((acc, item) => {
      if (item.hasOwnProperty('combine')) {
        const combineItems = typeof item.combine === 'function' ? item.combine() : item.combine;
        const combineValues = getDefaultValues(combineItems);

        return Object.assign(acc, combineValues);
      }

      return Object.assign(acc, { [item.id]: item.defaultValue ?? null });
    }, {});
};

const Form = ({
  filters = {},
  manual,
  onSubmit,
  onFormDirtyFields,
  customInputs,
  footerRender,
  headerRender,
  isFormInclusive,
  customClass,
}: FormProps & {
  manual: IManual[];
}) => {
  const defaultValues = getDefaultValues(manual);
  const filterValues = { ...defaultValues, ...filters };

  const { formState, ...formProps } = useForm({
    defaultValues: filterValues,
  });

  const { classes } = useStyles();

  const { dirtyFields } = formState;

  useEffect(() => {
    onFormDirtyFields && onFormDirtyFields(dirtyFields);
  }, [formState]); // eslint-disable-line

  const CustomInputComponents = React.useCallback((data) => {
    return customInputs(data);
  }, []); // eslint-disable-line

  const HeaderComponent = React.useCallback(
    () =>
      !!headerRender &&
      headerRender({
        resetForm: formProps.reset,
        dirtyFields: dirtyFields,
      }),
    [formState] // eslint-disable-line
  );

  const FooterComponent = React.useCallback(
    () =>
      !!footerRender &&
      footerRender({
        formState,
        dirtyFields: dirtyFields,
        ...formProps,
      }),
    [formState] // eslint-disable-line
  );

  return (
    <FormProvider formState={formState} {...formProps}>
      <FormComponent
        onSubmit={
          !!onSubmit
            ? formProps.handleSubmit(async (data) => {
                try {
                  await onSubmit(data, formProps.reset, formProps);
                } catch (e) {
                  formProps.setError('internalSubmit', {
                    type: 'internal',
                    message: 'Something went wrong.',
                  });
                }
              })
            : null
        }
      >
        <div
          css={
            customClass
              ? customClass
              : isFormInclusive
              ? classes.simpleContainer
              : classes.container
          }
        >
          <HeaderComponent />
          <div css={classes.innerContainer}>
            {manual
              .filter((item) => {
                if (typeof item.show === 'function') {
                  return item.show(formProps.getValues());
                }
                return item.show !== false;
              })
              .map((item, index) => {
                if (item.hasOwnProperty('combine')) {
                  return (
                    <div
                      css={isFormInclusive ? classes.simpleRow : classes.row}
                      key={`parent-combined-${index}`}
                    >
                      {!!item?.name && !item?.hideName ? (
                        <label style={{ alignSelf: 'center' }}>{item.name}</label>
                      ) : (
                        <></>
                      )}
                      <CombineComponent key={`combined-${index}`} item={item} />
                    </div>
                  );
                }

                return (
                  <div
                    css={[
                      classes.row,
                      item.bold !== false && classes.bold,
                      item.hideName && classes.full,
                      isFormInclusive ? classes.simpleRow : classes.row,
                    ]}
                    key={`parent-single-${item.id}`}
                  >
                    {!!item?.name && !item?.hideName ? (
                      <label style={{ alignSelf: 'center' }}>{item.name}</label>
                    ) : (
                      <></>
                    )}
                    <ElementComponent
                      isFormInclusive={isFormInclusive}
                      key={`single-${item.id}`}
                      item={item}
                    />
                  </div>
                );
              })}
            {!!customInputs && <CustomInputComponents filters={filters} />}
          </div>
          <FooterComponent />
        </div>
      </FormComponent>
    </FormProvider>
  );
};

const useFormBuilder = (manual: IManual[]) => {
  const component = useCallback(
    ({
      filters,
      onSubmit,
      customInputs,
      footerRender,
      headerRender,
      isFormInclusive,
      onFormDirtyFields,
      onChange,
      customClass,
    }: FormProps) => {
      return (
        <Form
          customClass={customClass}
          manual={manual}
          filters={filters}
          onSubmit={onSubmit}
          onChange={onChange}
          onFormDirtyFields={onFormDirtyFields}
          customInputs={customInputs}
          headerRender={headerRender}
          footerRender={footerRender}
          isFormInclusive={isFormInclusive}
        />
      );
    },
    [manual]
  );

  return {
    FormComponent: component,
  };
};

const useStyles = makeStyles({
  base: {
    container: {
      borderRadius: '0.5rem',
      padding: '5rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '3rem',
      width: '75rem',
      position: 'relative',
    },
    simpleContainer: {
      borderRadius: '0.5rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '3rem',
      width: '100%',
      padding: '4rem',
      position: 'relative',
    },
    innerContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '3rem',
      width: '100%',
      maxHeight: '90dvh',
      scrollSnapType: 'y proximity',
      '&:after': {
        display: 'block',
        content: '""',
        scrollSnapAlign: 'end',
      },
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      width: '100%',
      gap: '1rem',
    },
    bold: {
      '& > span': {
        fontWeight: 600,
      },
    },
    full: {
      '& > div': {
        width: '100%',
      },
    },
    simpleRow: {
      display: 'flex',
      alignItems: 'flex-end',
      width: '100%',
      '& > span': {
        fontWeight: 600,
      },
    },
    singleInputContainer: {
      width: '45rem',
    },
    simpleSingleInputContainer: {
      width: '100%',
    },
    combinedInputContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 18,
      width: '45rem',
      justifyContent: 'space-between',
    },
    tagContainer: {
      display: 'flex',
      gap: '0.5rem',
      marginTop: '1.5rem',
      flexWrap: 'wrap',
    },
    tagItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      background: styles.color.xxLightGrey,
      borderRadius: '2rem',
      padding: '0.5rem 1rem',
    },
    selectTagContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    selectTag: {
      display: 'flex',
      alignItems: 'flex-end',
      gap: '1rem',
      '& > span': {
        width: '30rem',
      },
    },
    formControl: {
      width: '100%',
    },
    closeIcon: {
      cursor: 'pointer',
      padding: '0 0.5rem',
      width: '2.2rem !important',
      height: '1.1rem !important',
    },
    textarea: {
      whiteSpace: 'pre-wrap',
      overflow: 'auto',
      resize: 'vertical',
      width: '100%',
      padding: '2rem 1rem',
      boxSizing: 'border-box',
      borderRadius: '1rem',
      color: styles.color.black,
      background: styles.color.xxLightPurple,
      minHeight: '10rem',
      maxHeight: '10rem',
      outline: 'none',
    },
    textareaContainer: {
      background: styles.color.xxLightPurple,
      position: 'relative',
      paddingBottom: '2rem',
      border: `1px solid ${styles.color.xxLightGrey}`,
      borderRadius: '1rem',
      '&:focus-within': {
        border: '2px solid var(--main)',
      },
    },
    textareaChars: {
      position: 'absolute',
      bottom: 0,
      right: '1rem',
      fontSize: '1.2rem',
      color: styles.color.xLightGrey,
    },
  },
  light: {
    simpleContainer: {
      background: styles.color.white,
      border: `1px solid ${styles.color.xxLightGrey}`,
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
    container: {
      background: styles.color.white,
      border: `1px solid ${styles.color.xxLightGrey}`,
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
  },
  dark: {
    simpleContainer: {
      background: styles.color.xxDarkPurple,
      border: `1px solid ${styles.color.xDarkPurple}`,
      boxShadow: '0px 0px 4px rgba(255, 255, 255, 0.15)',
    },
    container: {
      background: styles.color.xxDarkPurple,
      border: `1px solid ${styles.color.xDarkPurple}`,
      boxShadow: '0px 0px 4px rgba(255, 255, 255, 0.15)',
    },
  },
});
export default useFormBuilder;
