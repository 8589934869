import React, { Fragment, useState } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import { TableCell } from '@material-ui/core';
import styled from '@emotion/styled';
import isEmpty from 'lodash/isEmpty';
import { Sandboxing } from 'models/Sandboxing';
import { useIntl } from 'react-intl';
import ActionBtn from 'views/components/table/ActionBtn';
import { btnMessages } from 'views/components/Button';
import { openModal } from 'state/modal/actions';
import { useDispatch } from 'react-redux';

import { Tooltip } from '@material-ui/core';
import BaseHoverableTableRow from 'views/components/table/HoverableTableRow';
import StatusIcon, { Status } from 'views/pages/SandboxPage/SandboxPanel/StatusIcon';
import {
  getStatusSummary,
  SandboxInstanceStatus,
  STATUS_SUMMARY,
} from 'models/Sandbox';
import { parseSandboxURL } from 'views/url';
import useIsCommunityPlan from 'hooks/useIsCommunityPlan';

interface IAllSandboxingTableRow {
  rowData: Sandboxing;
  refetch?: (sha256: string, id: string) => void;
  blur?: boolean;
}

const messages = {
  pending: 'Results available soon. Reload table to see latest status.',
  generateReport: 'Generate Report',
};

const HoverableTableRow = styled(BaseHoverableTableRow)<{ blur?: boolean }>`
  filter: ${({ blur }) => (blur ? 'blur(4px)' : 'none')};
  user-select: ${({ blur }) => (blur ? 'none' : 'auto')};
  cursor: pointer;
`;

const Actions = styled.div`
  white-space: nowrap;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;

  & #reload-icon[data-active='true'] {
    animation: spin 0.45s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Bold = styled(TableCell)`
  font-weight: bold !important;
`;

const AllSandboxingTableRow = ({ rowData, refetch, blur = false }: IAllSandboxingTableRow) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isReloading, setIsReloading] = useState(false);
  const isCommunityPlan = useIsCommunityPlan();

  const statusMessage = getStatusSummary(
    rowData.status as keyof typeof STATUS_SUMMARY,
    rowData.failedReason
  );

  return (
    <Fragment>
      <HoverableTableRow
        blur={blur}
        renderCells={() => (
          <Fragment>
            <Bold>{rowData.sandboxOn}</Bold>
            <TableCell>{rowData?.target ?? '-'}</TableCell>
            <TableCell>{rowData?.type}</TableCell>
            <TableCell>{rowData.sandboxProvider}</TableCell>
            <TableCell>
              <Tooltip
                title={
                  statusMessage ? <div style={{ whiteSpace: 'pre-line' }}>{statusMessage}</div> : ''
                }
                placement='top'
              >
                <span>
                  <StatusIcon status={rowData.status as Status} />
                </span>
              </Tooltip>
            </TableCell>
            <TableCell align='right'>
              <Actions>
                {[
                  SandboxInstanceStatus.DELAYED,
                  SandboxInstanceStatus.STARTED,
                  SandboxInstanceStatus.PENDING,
                  SandboxInstanceStatus.COLLECTING_DATA,
                ].includes(rowData.status as SandboxInstanceStatus) ? (
                  <ActionBtn
                    disabled={isEmpty(rowData?.sha256)}
                    id='reload-icon'
                    name='rescan'
                    title='Reload'
                    aria-label='Reload'
                    data-active={isReloading}
                    onClick={async () => {
                      if (!!rowData?.sha256) {
                        setIsReloading(true);
                        await refetch?.(rowData.sha256, rowData.id);
                        setIsReloading(false);
                      }
                    }}
                  />
                ) : null}
                <Tooltip
                  title={
                    [SandboxInstanceStatus.DELAYED, SandboxInstanceStatus.STARTED].includes(
                      rowData.status as SandboxInstanceStatus
                    )
                      ? messages.pending
                      : ''
                  }
                  placement='top'
                >
                  <div>
                    <ActionBtn
                      isShown={rowData.status === SandboxInstanceStatus.SUCCEEDED}
                      disabled={isCommunityPlan}
                      name='generate-report'
                      title={
                        isCommunityPlan
                          ? 'Generate Report is not available for Community Plan users'
                          : messages.generateReport
                      }
                      aria-label={messages.generateReport}
                      onClick={() => {
                        dispatch(
                          openModal('GENERATE_REPORT', {
                            type: 'sandbox',
                            id: rowData.id,
                          })
                        );
                      }}
                    />
                    <ActionBtn
                      name='open-view'
                      style={{ marginLeft: 8 }}
                      disabled={
                        blur ||
                        rowData.status !== SandboxInstanceStatus.SUCCEEDED ||
                        isEmpty(rowData.sha256)
                      }
                      title={intl.formatMessage(btnMessages.info)}
                      aria-label={intl.formatMessage(btnMessages.info)}
                      onClick={() =>
                        window.open(
                          parseSandboxURL(rowData.sha256, {
                            sandboxType: rowData.type,
                            sandboxId: rowData.id,
                          }),
                          '_blank'
                        )
                      }
                    />
                  </div>
                </Tooltip>
              </Actions>
            </TableCell>
          </Fragment>
        )}
      />
    </Fragment>
  );
};

export default AllSandboxingTableRow;
