import tenant from 'tenants';
import type { Tabs } from 'tenants/config';
import { useUser } from 'views/components/providers/UserProvider';
import useHasFeature from './useHasFeature';

type TabValue<T extends keyof Tabs> = Tabs[T] extends (infer U)[] | 'all' | undefined ? U : 'all';

const tabsFeatureMap = {
  hash: 'hash_search',
  metadata: 'metadata_search',
  ioc: 'ioc_user',
  rulesets: 'hunting_yara_rules',
  liveHunt: 'live_hunt',
  historicalHunt: 'historical_hunt',
  allSandboxing: 'sandbox_search',
  mySandboxing: 'sandbox_request',
};

export function useIsTabEnabled() {
  const user = useUser();
  const { checkFeature } = useHasFeature();

  const hasLimitedAccess = user.context?.isLimitedAccess;

  function isEnabled<Page extends keyof Tabs, Tab extends TabValue<Page>>(
    page: Page,
    tab: Tab | 'all'
  ) {
    if (hasLimitedAccess) {
      return checkFeature(tabsFeatureMap[tab as keyof typeof tabsFeatureMap]);
    } else if (!tenant.tabsEnabled) {
      return true;
    } else if (tenant.tabsEnabled[page] === 'all') {
      return true;
    } else if (tab === 'all') {
      return false;
    } else if (Array.isArray(tenant.tabsEnabled[page])) {
      // @ts-ignore
      return tenant.tabsEnabled[page].includes(tab);
    }
  }

  function defaulTab<T>(page: keyof Tabs, urls: T, fallback: keyof T) {
    if (!tenant.tabsEnabled) {
      return urls[fallback];
    } else if (tenant.tabsEnabled[page] === 'all') {
      return urls[fallback];
    } else if (
      Array.isArray(tenant.tabsEnabled[page]) &&
      urls[tenant.tabsEnabled[page]![0] as keyof T]
    ) {
      return urls[tenant.tabsEnabled[page]![0] as keyof T];
    }
    return urls[fallback];
  }

  return {
    isEnabled,
    defaulTab,
  };
}
